// Dependencies
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..

function SignIn() {
  const { t } = useTranslation();
  const {
    loginWithRedirect,
  } = useAuth0();

  return (
    <button
      type="button"
      onClick={() => loginWithRedirect()}
      className="btn btn-primary"
    >
      { t('components.auth0.signIn') }
    </button>
  );
}

export default SignIn;
