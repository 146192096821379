// Dependencies
import { Auth0Provider } from '@auth0/auth0-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import i18nextConfig from './config/i18nextConfig';
import 'intro.js/introjs.css';
// Components, config, utils ..
import App from './base/App';
import './resources/font';
import configureStore from './store/configureStore';
import LoadingFallback from './components/common/LoadingFallback';

const store = configureStore();

if (
  !process.env.REACT_APP_DOMAIN
  || !process.env.REACT_APP_CLIENT_ID
  || !process.env.REACT_APP_AUDIENCE
) throw new Error('Auth0 environment variables not defined.');

i18nextConfig();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<LoadingFallback />}>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN}
        clientId={process.env.REACT_APP_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUDIENCE,
        }}
      >
        <App />
      </Auth0Provider>
    </Provider>
  </Suspense>,
);
