// Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Components, actions, assets..
import EditUserForm from '../forms/EditUserForm';
import CloseModalButton from './CloseModalButton';

function EditUserModal(props) {
  const { content, closeModal } = props;
  return (
    <div className="modal-header border-0 align-items-start">
      <div className="w-100 d-flex flex-column align-items-center">
        <EditUserForm user={content.user} />
      </div>
      <CloseModalButton closeModal={closeModal} />
    </div>
  );
}

export default EditUserModal;

EditUserModal.propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
    user: PropTypes.shape({}),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
