/* eslint-disable */
// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseModalButton from './CloseModalButton';

// Components, assets, actions etc..

function SuccessModal(props) {
  const { t } = useTranslation();
  const { closeModal, children = null } = props;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center mb-1">{t('components.modals.successModal.notice')}</h6>
            <h1 className="header-title text-center">{t('components.modals.successModal.title')}</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column">
        <div className="text-center pb-5">
          <i className="display-1 text-success far fa-check-circle" />
        </div>
        <div className="col">
          <h3 className="text-center">
            <strong>{t('components.modals.successModal.operationAlert')}</strong>
          </h3>
          <h4 className="text-center">
            {t('components.modals.successModal.content')}
            {children}
          </h4>
        </div>
      </div>
    </>
  );
}

export default SuccessModal;

SuccessModal.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
};
