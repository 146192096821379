import { FETCH_SECURITIES, FETCH_SECURITIES_EDGES } from '../../actions/types';

const INITIAL_STATE = null;

export default function securityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SECURITIES:
      return {
        ...state,
        all: action.payload.result,
      };
    case FETCH_SECURITIES_EDGES:
      return {
        ...state,
        totals: {
          ...action.payload.totals,
        },
      };
    default:
      return state;
  }
}
