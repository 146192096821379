import { FEED_MODAL, TOGGLE_MODAL } from './types';

export const toggleModal = (content) => {
  const payload = content === 'close' ? null : content;
  const fullscreen = !!content?.fullscreen;
  return {
    type: TOGGLE_MODAL,
    payload,
    fullscreen,
  };
};

export const feedModal = (payload) => ({
  type: FEED_MODAL,
  payload,
});
