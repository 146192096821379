// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, actions, assets..
import EditAvatarForm from '../forms/EditAvatarForm';
import CloseModalButton from './CloseModalButton';

function EditUserModal(props) {
  const { closeModal } = props;
  return (
    <div className="modal-header border-0 align-items-start">
      <div className="w-100 d-flex flex-column align-items-center">
        <EditAvatarForm />
      </div>
      <CloseModalButton closeModal={closeModal} />
    </div>
  );
}

export default EditUserModal;

EditUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
