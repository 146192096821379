const getPaywallState = (model, org) => {
  const type = org?.subscription?.type;

  if (model === 'savings' || model === 'security') {
    if (type === process.env.REACT_APP_FREE_PRICING_ALIAS) {
      return {
        activePay: true, payLimit: 5, payPageName: model,
      };
    }
  }

  return { activePay: false, payLimit: 10, payPageName: model };
};

export default getPaywallState;
