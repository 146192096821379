/* eslint-disable react/jsx-props-no-spreading,react/require-default-props */

// Dependencies
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, assets, actions, etc..
import Dropzone from 'react-dropzone';
import useForm from '../../../hooks/useForm';
import FormInput from '../FormInput';

function EditOrganizationForm(props) {
  const { t } = useTranslation();
  const {
    id = '', onSubmitAction, name = '', description = '', submitButton = <div />,
  } = props;
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const { onSubmit } = useForm((body) => {
    const properties = {
      ...body,
      id: id || undefined,
      brandLogoUrl: logo,
    };
    if (!body?.description) properties.description = 'Organization without any description 🥲';
    // if (!body?.brandLogoUrl) properties.brandLogoUrl = 'https://cdn.gethypercube.com/images/vendors/hypercube-tenants.svg';
    dispatch(onSubmitAction(properties));
  });

  const validateFields = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  const [previewImage, setPreviewImage] = useState(null);
  const [logo, setLogo] = useState(null);

  const onDrop = async (acceptedFiles, { setFieldValue }) => {
    setError(null);

    try {
      if (acceptedFiles.length !== 1) {
        throw new Error('Please upload only one file.');
      }

      const file = acceptedFiles[0];

      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];

      if (!allowedFileTypes.includes(file.type)) {
        throw new Error('Invalid format. Supported image types are JPEG, PNG, SVG, and WEBP.');
      }

      const reader = new FileReader();

      reader.onload = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);

      setLogo(file);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className="d-flex justify-content-center mt-1 pr-5 pl-5 pt-3 pb-3">
      <Formik
        initialValues={{
          name,
          description,
          submitButton,
        }}
        validate={validateFields}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting, errors, values, setFieldValue,
        }) => (
          <Form className="p-0 w-100">
            <div className="row">
              <div className="col-12 mb-3">
                <FormInput
                  errors={errors}
                  label={t('components.forms.organizations.editOrganizationForm.name')}
                  name="name"
                  placeholder={t('components.forms.organizations.editOrganizationForm.namePlaceholder')}
                />
              </div>
              <div className="col-12 mb-3">
                <p>Upload your organization logo</p>
                {previewImage && !error && (
                <section className="mb-3">
                  <p className="text-center">Preview:</p>
                  <div className="d-flex justify-content-center">
                    {previewImage.includes('image/svg+xml') ? (
                      <object
                        type="image/svg+xml"
                        data={previewImage}
                        width="100%"
                        height="200px"
                        aria-label="SVG Preview"
                      >
                        Your browser does not support SVG
                      </object>
                    ) : (
                      <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    )}
                  </div>
                </section>
                )}
                <Dropzone className="d-flex" onDrop={(acceptedFiles) => onDrop(acceptedFiles, { setFieldValue })}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="d-flex justify-content-center bg-light border-dropzone">
                      <div {...getRootProps()} className="d-flex flex-column justify-content-center p-5">
                        <input {...getInputProps()} />
                        <i className="d-flex align-self-center display-1 fas fa-cloud-upload-alt text-primary" />
                        <p className="pt-2">Click me or drop files onto this area.</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {error && (
                <div className="text-danger mt-3">
                  {error}
                </div>
                )}
              </div>
              <div className="col-12">
                <FormInput
                  errors={errors}
                  label={t('components.forms.organizations.editOrganizationForm.description')}
                  name="description"
                  className="form-control text-monospace"
                  placeholder={t('components.forms.organizations.editOrganizationForm.descriptionPlaceholder')}
                  inline={false}
                  as="textarea"
                  rows="5"
                />
              </div>
            </div>
            <div className="card-footer d-flex w-100 pb-2">
              { submitButton }
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

EditOrganizationForm.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  onSubmitAction: PropTypes.func.isRequired,
  submitButton: PropTypes.node,
};

export default EditOrganizationForm;
