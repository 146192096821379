// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleActiveIntegration } from '../../../actions/dashboard/integrationsActions';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';

function DisableIntegrationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { closeModal, content } = props;
  const { id } = content;
  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center">{ t('components.modals.integrations.disableIntegration.yourIntegration') }</h6>
            <h1 className="header-title text-center">{ t('components.modals.integrations.disableIntegration.disableIntegration') }</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column container-fluid pt-5">
        <div className="col">
          <h4 className="text-center">{ t('components.modals.integrations.disableIntegration.areYouSure') }</h4>
          <h4 className="text-center">
            { t('components.modals.integrations.disableIntegration.byDisablingYour') }
            <strong>{ t('components.modals.integrations.disableIntegration.noLonger') }</strong>
            { t('components.modals.integrations.disableIntegration.getUpdatesTo') }
          </h4>
        </div>
        <div className="row justify-content-center pt-4">
          <button
            type="submit"
            className="btn btn-danger"
            onClick={() => dispatch(toggleActiveIntegration(id, false))}
          >
            { t('components.modals.integrations.disableIntegration.disableAccount') }
          </button>
        </div>
      </div>
    </>
  );
}

export default DisableIntegrationModal;

DisableIntegrationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.shape({
    id: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
};
