/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './inputsStyles.css';

function Switch({
  isOn = false,
  handleToggle = () => { },
}) {
  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={isOn}
          onClick={handleToggle}
        />
        <span className="slider" />
      </label>
    </div>
  );
}

Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default Switch;
