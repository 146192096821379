/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './inputsStyles.css';
import { Button } from 'react-bootstrap';

function BaseButton({
  variant = 'primary',
  styles = {},
  handleClick = () => { },
  label = '',
}) {
  return (
    <Button
      style={{ ...styles }}
      onClick={() => handleClick()}
    >
      {label}
    </Button>
  );
}

BaseButton.propTypes = {
  variant: PropTypes.func.isRequired,
  styles: PropTypes.shape({}).isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default BaseButton;
