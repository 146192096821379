// Dependencies
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// Components, assets, actions etc..
import CloseModalButton from './CloseModalButton';
import KnowledgeBaseFiller from '../fillers/KnowledgeBaseFiller';
import InstructionFiller from '../fillers/InstructionFiller';

function KnowledgeBaseModal(props) {
  const { closeModal, content } = props;
  const { icon, knowledgeBase, instructions } = content;
  const [kbAvailable, setKbAvailable] = useState(false);

  useEffect(() => {
    async function checkUrlResolve() {
      try {
        const res = await axios.get(knowledgeBase);
        setKbAvailable(res.status === 200);
      } catch (e) { setKbAvailable(false); }
    }
    checkUrlResolve();
  });

  const getKnowledgeBaseEmbedded = () => {
    if (kbAvailable) {
      return <embed src={knowledgeBase} style={{ width: '100%', height: '100%' }} />;
    } if (instructions) {
      return <InstructionFiller instructions={instructions} />;
    }
    return <KnowledgeBaseFiller fromView={content.savingType} />;
  };

  return (
    <>
      <div className="modal-header" tabIndex="-1">
        <img src={icon} alt="Logo" height="50" width="50" />
        <CloseModalButton closeModal={closeModal} />
      </div>
      {getKnowledgeBaseEmbedded()}
    </>
  );
}

export default KnowledgeBaseModal;

KnowledgeBaseModal.propTypes = {
  content: PropTypes.shape({
    savingType: PropTypes.string,
    title: PropTypes.string,
    instructions: PropTypes.arrayOf(PropTypes.string),
    knowledgeBase: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
