import { vendors } from '../constants/clouds';

export const getVendorsColors = (name) => {
  let color = '';

  if (name === vendors.AWS) {
    color = 'linear-gradient(to bottom, rgba(33, 150, 243, 1) 0%, rgba(23, 100, 203, 1) 100%)';
  }
  if (name === vendors.GCP) {
    color = 'linear-gradient(90deg, #4285F4, #34A853, #FBBC05)';
  }
  if (name === vendors.Azure) {
    color = 'linear-gradient(to bottom, rgba(0, 122, 255, 1) 0%, rgba(0, 92, 205, 1) 100%)';
  }
  if (name === vendors.OCI) {
    color = 'linear-gradient(90deg, #F80000, #FF1F00)';
  }
  return color;
};

export const getAbbreviaton = (name) => {
  const entries = Object.entries(vendors);
  let activeVendor = '';
  entries.forEach((entry) => {
    if (entry[1] === name) {
      // eslint-disable-next-line prefer-destructuring
      activeVendor = entry[0];
    }
  });
  return activeVendor;
};
