// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, actions, assets..
import ApiKeyForm from '../forms/ApiKeyForm';
import CloseModalButton from './CloseModalButton';

function ApiKeyModal(props) {
  const { content, closeModal } = props;
  return (
    <div className="modal-header border-0 align-items-start">
      <div className="w-100 d-flex flex-column align-items-center">
        <ApiKeyForm accessKeyId={content.accessKeyId} closeModal={closeModal} />
      </div>
      <CloseModalButton closeModal={closeModal} />
    </div>
  );
}

export default ApiKeyModal;

ApiKeyModal.propTypes = {
  content: PropTypes.shape({
    accessKeyId: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
