// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';
import CreateMembersInvitationsForm from '../../forms/organizations/CreateMembersInvitationsForm';

function AddInvitationMemberModal(props) {
  const { content, closeModal } = props;
  const {
    organizationId, title, description, icon, actions = {},
  } = content;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={icon} className="img-fluid mb-2" alt="Logo" height="50" width="50" />
          <h1 className="header-title text-center">{title}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-3">
          <h4 className="text-center">{description}</h4>
        </div>
        <CreateMembersInvitationsForm
          onSubmitActions={actions}
          organizationId={organizationId}
        />
      </div>
    </>
  );
}

AddInvitationMemberModal.propTypes = {
  content: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      inviteAction: PropTypes.func.isRequired,
      addMemberAction: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AddInvitationMemberModal;
