import React from 'react';
import PropTypes from 'prop-types';

function CloseModalButton(props) {
  const { closeModal } = props;
  return (
    <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
      <span aria-hidden="true">&times;</span>
    </button>
  );
}

export default CloseModalButton;

CloseModalButton.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
