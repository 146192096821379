/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { spendingTypes } from '../../constants/spendings';
import { USD_EXCHANGE_RATE } from '../../constants/exchangesRate';
import EmptyData from '../common/emptyData';

const totalParser = (amount) => amount > 0.00 ? amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0.0;

function MonthTotal(props) {
  const {
    titles, totals,
  } = props;

  const getSpendingByTitle = (title) => {
    let activeSpending = '';

    activeSpending = (title === spendingTypes.lastMonth && totals?.totalByLastMonth)
      || (title === spendingTypes.lastTwoMonths && totals?.totalByLastTwoMonths)
      || (title === spendingTypes.lifetimeTotal && totals?.totalByLifetime)
      || activeSpending;

    if (!activeSpending || activeSpending <= 0) {
      return <EmptyData height="1.5rem" />;
    }

    return (
      <span className="h2 mb-0 col-4">
        {`$ ${totalParser(activeSpending)}`}
      </span>
    );
  };

  return (

    <Container fluid>
      <Row className="g-3">
        {titles.map((title, index) => (
          <Col md={4}>
            <Card>
              <Card.Header>
                <h4 className="card-header-title">{`${title} (${USD_EXCHANGE_RATE})`}</h4>
              </Card.Header>
              <Card.Body>
                {getSpendingByTitle(title)}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

MonthTotal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  titles: PropTypes.array.isRequired,
  totals: PropTypes.shape({
    totalByLifetime: PropTypes.number,
    totalByLastMonth: PropTypes.number,
    totalByLastTwoMonths: PropTypes.number,
  }).isRequired,

};

export default MonthTotal;
