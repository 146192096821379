// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// Components, actions, assets, etc..
import SignIn from '../../components/auth0/SignIn';
import SignUp from '../../components/auth0/SignUp';
import logo from '../../images/logos/obok-logo.svg';
import './Landing.css';
import { setUser, signIn } from '../../actions/userActions';

const USER_REGISTRATION_CUSTOM_CLAIM = 'https://app.getobok.com/registration/';
const USER_INVITATION_CUSTOM_CLAIM = 'https://app.getobok.com/invitation/';

function Landing() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user, isAuthenticated, getAccessTokenSilently, isLoading,
  } = useAuth0();

  const handleAuthenticatedUser = () => {
    const isRegistrated = user[USER_REGISTRATION_CUSTOM_CLAIM];
    const isInvited = user[USER_INVITATION_CUSTOM_CLAIM];

    dispatch(setUser(user));
    dispatch(signIn(getAccessTokenSilently));

    // TODO: avoid user go to dashboard after finishing the first onboarding step
    if (!isRegistrated && !isInvited) {
      return <Navigate to="/onboarding" />;
    }
    return <Navigate to="/dashboard" />;
  };

  return (isAuthenticated && !isLoading) ? (
    handleAuthenticatedUser()
  ) : (
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
          <div className="p-5">
            <svg width="270px" height="60px">
              <image href={logo} className="img-fluid" alt="Obok logo" width="100%" />
            </svg>
          </div>
          <p data-testid="visible" className="text-muted text-center mb-3">
            { t('views.dashboard.landing.welcomeBack') }
          </p>
          <div className="row align-items-center justify-content-center mb-5">
            <SignIn />
          </div>
          <p className="text-muted text-center mb-3">
            <span>{ t('views.dashboard.landing.dontHave') }</span>
            <SignUp />
          </p>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div id="bg-landing" className="vh-100 mr-n3" />
        </div>
      </div>
    </div>
  );
}

export default Landing;
