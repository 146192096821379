// Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components, actions, assets..
import CloseModalButton from './CloseModalButton';
import { deleteUser } from '../../actions/userActions';

function DeleteUserModal(props) {
  const dispatch = useDispatch();
  const { closeModal } = props;

  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center">Account</h6>
            <h1 className="header-title text-center">Delete your account</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="container-fluid">
        <div className="col pt-5">
          <h4 className="text-center">Are you sure you want to delete your account?</h4>
          <h4 className="text-center">
            By deleting your account, you will lose all your information.
          </h4>
          <h4 className="text-center">
            This operation
            <strong>can not be undone</strong>
          </h4>
        </div>
        <div className="row justify-content-center pt-4 pb-4">
          <button type="submit" className="btn btn-danger" onClick={() => dispatch(deleteUser())}>
            Delete your account
          </button>
        </div>
      </div>
    </>
  );
}

export default DeleteUserModal;

DeleteUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
