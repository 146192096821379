import hypercube from '../api/hypercube';
import { fetchExpensesByCategory, fetchExpensesByVendor } from './spending/chartsActions';
import { fetchIntegrations, fetchIntegrationsEdges } from './dashboard/integrationsActions';
import { toggleModal } from './modalActions';
import { fetchNotifications, fetchNotificationsEdges, fetchLatestNotifications } from './dashboard/notificationsActions';
import { fetchSavings } from './dashboard/savingsActions';
import { fetchSecurities } from './dashboard/securityActions';
import { fetchTransactions, fetchSpendingTotal } from './spending/transactionsActions';
import { fetchVendors } from './dashboard/vendorActions';
import { fetchAccounts, fetchThreatsIdentified, fetchThreatsActive } from './dashboard/widgetsActions';
import {
  fetchDefaultOrganization,
  fetchOrganizations,
  fetchOrganizationsEdges,
} from './organization/organizationActions';
import {
  FEED_MODAL, RELOAD_USER, SET_USER, SIGN_IN, SIGN_OUT,
} from './types';

export const signIn = (getToken) => async (dispatch) => {
  const token = await getToken();
  let user;
  let currentOrganization;

  try {
    const res = await hypercube.post('/users/login', null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: SIGN_IN, payload: res.data });
    user = res.data?.user;

    const currentOrgResponse = await hypercube.patch(`/users/currentOrg/${user.defaultOrg}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    currentOrganization = currentOrgResponse.data;
  } catch (err) {
    console.warn(err.message);
    return;
  }
  // Organizations (This has to load before all the user data).
  dispatch(fetchOrganizations({}));
  dispatch(fetchDefaultOrganization(user.defaultOrg));
  dispatch(fetchOrganizationsEdges());

  // Savings
  dispatch(fetchSavings({ organizationId: currentOrganization._id }));

  // Integrations
  dispatch(fetchIntegrations({ organizationId: currentOrganization._id }));
  dispatch(fetchIntegrationsEdges({ organizationId: currentOrganization._id }));

  // Notifications
  dispatch(fetchNotifications({ organizationId: currentOrganization._id }));
  dispatch(fetchLatestNotifications({ organizationId: currentOrganization._id, latest: 8 }));
  dispatch(fetchNotificationsEdges({ organizationId: currentOrganization._id }));

  // Securities
  dispatch(fetchSecurities({ organizationId: currentOrganization._id }));

  // Transactions
  dispatch(fetchTransactions({ organizationId: currentOrganization._id, limit: 'all' }));
  dispatch(fetchSpendingTotal({ organizationId: currentOrganization._id }));
  dispatch(fetchExpensesByCategory({ organizationId: currentOrganization._id }));
  dispatch(fetchExpensesByVendor({ organizationId: currentOrganization._id }));

  // Vendors
  dispatch(fetchVendors());

  // Widgets
  dispatch(fetchAccounts({ organizationId: currentOrganization._id }));
  dispatch(fetchThreatsIdentified({ organizationId: currentOrganization._id }));
  dispatch(fetchThreatsActive({ organizationId: currentOrganization._id }));
};

export const setUser = (userId) => ({
  type: SET_USER,
  payload: userId,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const reloadUser = () => async (dispatch) => {
  try {
    const res = await hypercube.get('users/me');
    dispatch({ type: RELOAD_USER, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'user reload', err },
    });
  }
};

export const editUser = (body) => async (dispatch) => {
  try {
    const res = await hypercube.patch('users/me', body);

    if (res.status === 200) {
      dispatch({
        type: FEED_MODAL,
        payload: { type: 'success' },
      });
      dispatch(reloadUser());
    }
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'account update', err },
    });
  }
};

export const checkEmail = async (email) => {
  try {
    const res = await hypercube.get(`users/checkEmail?email=${email}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const completeTutorial = (tutorialName) => async (dispatch) => {
  try {
    const res = await hypercube.patch(`users/tutorial?name=${tutorialName}`);
    console.log('reloadUser: ', res.data);
    dispatch({ type: RELOAD_USER, payload: { user: res.data } });
  } catch (err) {
    console.log(err);
  }
};

export const deleteUser = () => async (dispatch) => {
  try {
    const res = await hypercube.delete('/users/me');

    if (res.status === 200) {
      window.location.reload();
    }
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'account deletion', err },
    });
  }
};

export const verifyEmail = () => async (dispatch) => {
  try {
    const res = await hypercube.get('users/verification');

    if (res.status === 200) {
      dispatch(
        toggleModal({ type: 'success' }),
      );
    }
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'verify email', err },
    });
  }
};

export const editAvatar = (data) => async (dispatch) => {
  try {
    const res = await hypercube.post('users/me/avatar', data);

    if (res.status === 200) {
      dispatch({
        type: FEED_MODAL,
        payload: { type: 'success' },
      });
      dispatch(reloadUser());
    }
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'avatar update', err },
    });
  }
};

export const updateUserIntro = (active) => async (dispatch) => {
  try {
    const res = await hypercube.patch('users/me', { intro: { active } });

    if (res.status === 200) {
      dispatch({
        type: FEED_MODAL,
        payload: { type: 'success' },
      });
      dispatch(reloadUser());
    }
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'account update', err },
    });
  }
};

export const setUserWithToken = (getToken) => async (dispatch) => {
  const token = await getToken();
  try {
    const res = await hypercube.post('/users/login', null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: RELOAD_USER, payload: res.data });
  } catch (err) {
    console.warn(err.message);
  }
};
