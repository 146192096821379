import hypercube from '../../api/hypercube';
import { FEED_MODAL, FETCH_SECURITIES_EDGES, FETCH_SECURITIES } from '../types';

export const fetchSecurities = (properties) => async (dispatch) => {
  let { limit, pageNumber, dataFilter } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  const params = {
    dataFilter,
    limit,
    organizationId: properties.organizationId,
    pageNumber,
    sortBy: 'updatedAt:desc',
    type: 'Security',
  };

  const res = await hypercube.get('/recommendations', { params });
  dispatch({ type: FETCH_SECURITIES, payload: res.data });
  dispatch({ type: FETCH_SECURITIES_EDGES, payload: res.data });
};

export const archivedSecurity = (properties) => async (dispatch) => {
  try {
    const { id, archived } = properties;
    const res = await hypercube.patch(`/recommendations/${id}`, { archived: !archived });
    return res.status === 201;
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'archiving security', err },
    });
    return false;
  }
};

export const fetchSecuritiesEdges = (properties) => async (dispatch) => {
  try {
    const requestString = `/recommendations/edges?type=${properties.type}&organizationId=${properties.organizationId}`;
    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_SECURITIES_EDGES, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'security edges', err },
    });
  }
};
