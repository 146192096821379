import {
  FETCH_ACCOUNTS_WIDGET,
  FETCH_THREATS_IDENTIFIED,
  FETCH_THREATS_ACTIVE,
} from '../../actions/types';

const INITIAL_STATE = {
  accounts: 0,
  threatsIdentified: null,
  threatsActive: null,
};

export default function modalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_WIDGET:
      return {
        ...state,
        accounts: action.payload,
      };
    case FETCH_THREATS_IDENTIFIED:
      return {
        ...state,
        threatsIdentified: action.payload,
      };
    case FETCH_THREATS_ACTIVE:
      return {
        ...state,
        threatsActive: action.payload,
      };
    default:
      return state;
  }
}
