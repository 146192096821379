import { CREATE_LOCATION, UPDATE_LOCATION } from '../actions/types';

const INITIAL_STATE = [];

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_LOCATION: {
      const newLocation = {
        name: action.payload.pathname,
        data: {
          titleFilter: null,
          currentTab: 'All',
          dataFilter: {},
          ...(action.payload.total && { total: action.payload.total }),
        },
      };

      // Add new location if queue is empty or size is lower than 2
      if (!state.length) {
        state.unshift(newLocation);
        return state;
      }

      // Check for match on array with pathname
      const isLocation = state.findIndex((location) => location.name === action.payload.pathname);

      // Add to queue if doesn't exist
      if (isLocation === -1) {
        state.unshift(newLocation);
        if (state.length > 2) {
          state.pop();
        }
      } else if (isLocation === 1) {
        // Move location to first
        state.splice(0, 0, state.pop());
      }

      return state;
    }

    case UPDATE_LOCATION: {
      return [
        ...state.map((location) => ({
          ...location,
          ...(location.name === action.payload.pathname && {
            data: { ...location.data, ...action.payload.data },
          }),
        })),
      ];
    }
    default:
      return state;
  }
};

export default navigationReducer;
