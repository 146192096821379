import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  FETCH_ALLOWED_PERMISSIONS,
  FETCH_API_KEYS,
  UPDATE_API_KEY,
} from '../../actions/types';

const INITIAL_STATE = {
  allowedPermissions: {},
  keys: null,
};

export default function apiKeysReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_API_KEYS:
      return { ...state, keys: action.payload };
    case FETCH_ALLOWED_PERMISSIONS:
      return { ...state, allowedPermissions: action.payload };
    case CREATE_API_KEY:
      return { ...state, keys: state.keys.concat(action.payload) };
    case UPDATE_API_KEY:
      return {
        ...state,
        keys: state.keys
          .map((key) => (key.accessKeyId === action.payload.accessKeyId ? action.payload : key)),
      };

    case DELETE_API_KEY:
      return {
        ...state,
        keys: state.keys.filter((key) => key.accessKeyId !== action.payload.accessKeyId),
      };
    default:
      return state;
  }
}
