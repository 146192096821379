/* eslint react/prop-types: 0 */
import 'chart.js/auto';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function CreatedVsResolved(props) {
  const { threatsIdentified } = props;
  const { resolved, created } = threatsIdentified;
  const { t } = useTranslation(); // Initialize useTranslation hook

  const labels = [
    '6 days ago',
    '5 days ago',
    '4 days ago',
    '3 days ago',
    '2 days ago',
    '1 day ago',
    'Today',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Created',
        data: created,
        borderColor: '#14192d',
        backgroundColor: '#14192d',
      },
      {
        label: 'Resolved',
        data: resolved,
        borderColor: '#7c69ef',
        backgroundColor: '#7c69ef',
        borderDash: [5, 5],
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback(value) {
            return Number.isInteger(value) ? value : '';
          },
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{t('views.dashboard.widgets.createdVsResolved.title')}</h4>
      </div>
      <div className="card-body d-flex align-items-center justify-content-center p-2">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

CreatedVsResolved.propTypes = {
  threatsIdentified: PropTypes.shape({}),
};

CreatedVsResolved.defaultProps = {
  threatsIdentified: {},
};

export default CreatedVsResolved;
