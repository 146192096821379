// Dependencies
import { useAuth0 } from '@auth0/auth0-react';
import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
// Components
import LoadingFallback from '../../components/common/LoadingFallback';

const Navbar = React.lazy(() => import('../../components/navbar/Navbar'));
const ZendeskWidget = React.lazy(() => import('../../components/widgets/ZendeskWidget'));

function Dashboard() {
  const { user } = useSelector((state) => state.auth);
  const { isAuthenticated } = useAuth0();
  return isAuthenticated && user ? (
    <div>
      <Suspense fallback={<LoadingFallback />}>
        <Navbar />
        <Outlet />
        {!!user.givenName && (
          <ZendeskWidget
            givenName={user.givenName}
            familyName={user.familyName}
            email={user.email}
          />
        )}
      </Suspense>
    </div>
    // TODO: maybe this line is because every time we refresh we lost the path where the user was
  ) : (
    <Navigate to="/" />
  );
}

export default Dashboard;
