import PropTypes from 'prop-types';
import React from 'react';
import { vendors } from '../../constants/clouds';

function AccountsAndIntegrations(props) {
  const { accounts } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title my-2">Active Integrations</h4>
          </div>
          <div className="card-body">
            <div className="row align-items-center px-3">
              <div className="col-6 col-md-3 mb-3 text-center">
                <div className="row align-items-center justify-content-center">
                  <span className="h1 mb-0">
                    {accounts[vendors.AWS] || 0}
                  </span>
                  <img src="https://cdn.gethypercube.com/images/vendors/hypercube-aws.svg" alt="AWS logo" className="rounded" width="45px" />
                </div>
                <h6 className="text-uppercase text-muted mb-2">
                  AWS Accounts
                </h6>
              </div>
              <div className="col-6 col-md-3 mb-3 text-center">
                <div className="row align-items-center justify-content-center">
                  <span className="h1 mb-0">
                    {accounts[vendors.Azure] || 0}
                  </span>
                  <img src="https://cdn.gethypercube.com/images/vendors/hypercube-azure.svg" alt="Azure logo" className="rounded" width="45px" />
                </div>
                <h6 className="text-uppercase text-muted mb-2">
                  Azure suscriptions
                </h6>
              </div>
              <div className="col-6 col-md-3 mb-3 text-center">
                <div className="row align-items-center justify-content-center">
                  <span className="h1 mb-0">
                    {accounts[vendors.GCP] || 0}
                  </span>
                  <img src="https://cdn.gethypercube.com/images/vendors/hypercube-google-cloud.svg" alt="GCP logo" className="rounded" width="45px" />
                </div>
                <h6 className="text-uppercase text-muted mb-2">
                  GCP Projects
                </h6>
              </div>
              <div className="col-6 col-md-3 mb-3 text-center">
                <div className="row align-items-center justify-content-center">
                  <span className="h1 mb-0">
                    {accounts[vendors.OCI] || 0}
                  </span>
                  <img src="https://cdn.gethypercube.com/images/vendors/hypercube-oracle.svg" alt="Oracle logo" className="rounded" width="45px" />
                </div>
                <h6 className="text-uppercase text-muted mb-2">
                  OCI Tenancies
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AccountsAndIntegrations.propTypes = {
  accounts: PropTypes.shape({
    'Amazon Web Services': PropTypes.number,
    'Microsoft Azure': PropTypes.number,
    'Google Cloud': PropTypes.number,
  }),
};

AccountsAndIntegrations.defaultProps = {
  accounts: {
    'Amazon Web Services': 0,
    'Microsoft Azure': 0,
    'Google Cloud': 0,
  },
};

export default AccountsAndIntegrations;
