// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';
import ChangeMemberRoleForm from '../../forms/organizations/ChangeMemberRoleForm';

function ChangeMemberRoleModal(props) {
  const { t } = useTranslation();
  const { content, closeModal } = props;
  const {
    role, memberId, organizationId, modalAction,
  } = content;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src="https://cdn.gethypercube.com/images/vendors/hypercube-change-role.svg" className="img-fluid mb-2" alt="Logo" height="50" width="50" />
          <h1 className="header-title text-center">{t('components.modals.organizations.changeMemberRoleModal.changeRole')}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-3">
          <h4 className="text-center">
            {t('components.modals.organizations.changeMemberRoleModal.yourCurrentRole', { role })}
          </h4>
        </div>
        <ChangeMemberRoleForm
          organizationId={organizationId}
          memberId={memberId}
          modalAction={modalAction}
        />
      </div>
    </>
  );
}

ChangeMemberRoleModal.propTypes = {
  content: PropTypes.shape({
    role: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
    modalAction: PropTypes.func.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ChangeMemberRoleModal;
