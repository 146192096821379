import React from 'react';
import PropTypes from 'prop-types';

function LineSpinner(props) {
  const { name } = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center m-2 p-5">
      <div
        className="spinner-border text-primary d-flex flex-column justify-content-center align-items-center"
        role="status"
      >
        <span className="sr-only" />
      </div>
      <div className="d-inline flex-column justify-content-center align-items-center p-2">
        <span>{name}</span>
      </div>
    </div>
  );
}

LineSpinner.propTypes = {
  name: PropTypes.string.isRequired,
};

export default LineSpinner;
