// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import parse from 'html-react-parser';

function InstructionFiller(props) {
  const { instructions } = props;

  const renderInstruction = (items) => items.map((item) => (
    <li className="text-muted" key={item}>
      {' '}
      {parse(item)}
      {' '}
    </li>
  ));

  return (
    <div className="container-fluid py-5 mb-3 px-5 mx-3 d-flex justify-content-center rounded w-100">
      <div className="row px-3 w-75">
        <h3>Instructions:</h3>
        <div className="col-md-12" data-aos="fade-up">
          {renderInstruction(instructions)}
        </div>
      </div>
    </div>
  );
}

export default InstructionFiller;

InstructionFiller.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
