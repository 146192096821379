// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, assets, actions, etc..
import FillerImg from '../../images/backgrounds/illustration-2.png';

function KnowledgeBaseModal({ fromView }) {
  const renderTitle = (title) => {
    switch (title) {
      case 'Security':
        return (
          <h2 className="display-4 text-center mb-4">
            Information in the making
            <span className="text-primary"> Security </span>
          </h2>
        );
      case 'Saving':
        return (
          <h2 className="display-4 text-center mb-4">
            Information in the making
            <span className="text-primary"> Savings </span>
            your spending?
          </h2>
        );
      default:
        return (
          <h1 className="display-4 text-center mb-4">
            Seems like you haven&apos;t any
            <span className="text-primary"> recommendations </span>
            yet.
          </h1>
        );
    }
  };

  return (
    <div className="container-fluid py-5 mb-3 px-5 mx-3 d-flex justify-content-center rounded w-100">
      <div className="row px-3 w-75">
        <div className="col-md-12 text-center" data-aos="fade-up">
          {renderTitle(fromView)}
          <img className="img-fluid h-100" src={FillerImg} alt="Filler" />
          <p className="font-size-lg text-center">
            We are working to provide you the full information from your integration.
            Please contact us to enable
            more information about the your current
            {fromView}
          </p>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeBaseModal;

KnowledgeBaseModal.propTypes = {
  fromView: PropTypes.string.isRequired,
};
