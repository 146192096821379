// Dependencies
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateIntegration } from '../../../actions/dashboard/integrationsActions';
import FormInput from '../../forms/FormInput';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';

function EditIntegrationModal(props) {
  const { t } = useTranslation();
  const { content, closeModal } = props;

  const dispatch = useDispatch();
  const updateHandler = (values, { setSubmitting }) => {
    const body = {
      name: values.name.trim(),
      description: values.description.trim() || '-',
      id: content.id,
    };
    dispatch(updateIntegration(body));
    setSubmitting(true);
  };

  const validateFields = (values) => {
    const errors = {};
    if (!values.name || values.name.trim() === '') {
      errors.name = 'Required';
    }
    return errors;
  };

  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <h6 className="header-pretitle mb-1 mt-3">
            {t('components.modals.integrations.editIntegration.integration')}
          </h6>
          <h1 className="header-title">{content.name}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-row align-items-center justify-content-center mt-1 p-4 w-full">
        <Formik
          initialValues={{ name: content.name, description: content.description }}
          validate={validateFields}
          onSubmit={updateHandler}
        >
          {({ isSubmitting, errors }) => (
            <Form className="w-100">
              <FormInput
                errors={errors}
                label={t('components.modals.integrations.editIntegration.name')}
                name="name"
                placeholder={t('components.modals.integrations.editIntegration.namePlaceholder')}
              />
              <div className="py-3">
                <FormInput
                  errors={errors}
                  label={t('components.modals.integrations.editIntegration.description')}
                  name="description"
                  as="textarea"
                  rows={6}
                  placeholder={t('components.modals.integrations.editIntegration.descriptionPlaceholder')}
                />
              </div>
              <div className="card-footer d-flex w-100">
                <button type="submit" className="btn btn-primary col-md-3 ml-auto mr-auto" disabled={isSubmitting}>
                  {t('components.modals.integrations.editIntegration.submit')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default EditIntegrationModal;

EditIntegrationModal.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
