// Dependencies
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components, assets, actions, etc..
import { useTranslation } from 'react-i18next';
import { connectIntegration } from '../../actions/dashboard/integrationsActions';
import useForm from '../../hooks/useForm';
import FormInput from './FormInput';

function IntegrationForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const { onSubmit } = useForm((body) => {
    dispatch(connectIntegration({ organizationId: currentOrganization._id, ...body }, 'aws'));
  });

  const validateFields = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t('components.forms.awsIntegrationForm.required');
    }
    if (values.name.length < 5 || values.name.length > 30) {
      errors.name = t('components.forms.awsIntegrationForm.nameShould');
    }
    if (!values.accountId) {
      errors.accountId = t('components.forms.awsIntegrationForm.required');
    }
    return errors;
  };

  return (
    <div className="d-flex justify-content-center mt-2 pl-2 pr-2 pt-3">
      <Formik
        initialValues={{
          accountId: '', name: '', description: '', type: 'aws',
        }}
        validate={validateFields}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className="form-row">
            <div className="form-group col-12 col-md-4">
              <FormInput
                errors={errors}
                label={t('components.forms.awsIntegrationForm.accountId')}
                name="accountId"
                placeholder="XXX-XXX-XXX"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <FormInput
                errors={errors}
                label={t('components.forms.awsIntegrationForm.name')}
                name="name"
                placeholder={t('components.forms.awsIntegrationForm.exName')}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <FormInput
                errors={errors}
                label={t('components.forms.awsIntegrationForm.description')}
                name="description"
                placeholder={t('components.forms.awsIntegrationForm.exDescription')}
              />
            </div>
            <div className="card-footer d-flex w-100 pb-2">
              <button
                type="submit"
                className="btn btn-primary col-md-2 ml-auto"
                disabled={isSubmitting}
              >
                {t('components.forms.awsIntegrationForm.connect')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default IntegrationForm;
