// Dependencies
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..

function SignIn() {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const logIn = () => loginWithRedirect({ screen_hint: 'signup' });

  return (
    <button
      className="btn text-primary m-0 b-0 p-0 pl-2"
      type="button"
      onClick={logIn}
    >
      { t('components.auth0.signUp') }
    </button>
  );
}

export default SignIn;
