// Dependencies
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, assets, actions, etc..
import { editAvatar } from '../../actions/userActions';

const renderInput = ({ setNewAvatar, setPreview }) => (
  <Dropzone
    className="d-flex"
    onDrop={(acceptedFiles) => {
      setPreview(acceptedFiles[0]);
      const fd = new FormData();
      fd.append('upload', acceptedFiles[0], acceptedFiles[0].name);
      setNewAvatar(fd);
    }}
  >
    {({ getRootProps, getInputProps }) => (
      <section className="d-flex justify-content-center bg-light border-dropzone">
        <div {...getRootProps()} className="d-flex flex-column justify-content-center p-5">
          <input {...getInputProps()} />
          <i className="d-flex align-self-center display-1 fas fa-cloud-upload-alt" />
          <p className="pt-2">Click me or drop files unto this area.</p>
        </div>
      </section>
    )}
  </Dropzone>
);

function EditAvatarForm() {
  const { t } = useTranslation();

  const [preview, setPreview] = useState(null);
  const [newAvatar, setNewAvatar] = useState(null);
  const [isDispatched, setIsDispatched] = useState();

  const dispatch = useDispatch();
  const update = (e) => {
    e.preventDefault();
    setIsDispatched(true);
    dispatch(editAvatar(newAvatar));
  };

  const changeAvatarHandler = () => {
    setNewAvatar(null);
  };

  return (
    <div className="container-fluid pt-5">
      <div className="col mb-4">
        <h6 className="header-pretitle text-center">{t('components.forms.editAvatarForm.yourAccount')}</h6>
        <h1 className="header-title text-center">{t('components.forms.editAvatarForm.updateImage')}</h1>
      </div>
      <form
        className="form-group px-4 d-flex flex-column justify-content-center align-content-center"
        onSubmit={update}
      >
        {newAvatar ? (
          <>
            <div className="column container-fluid text-center">
              <h3>{`Ready to upload: ${preview.name}`}</h3>
              <img
                width="150"
                height="150"
                className="img-thumbnail"
                src={URL.createObjectURL(preview)}
                alt="User Avatar"
              />
            </div>
            <div className="col-12 d-flex justify-content-center pt-4">
              <button className="btn btn-primary mx-2" type="submit" disabled={isDispatched}>
                Upload
              </button>
              <button
                className="btn btn-secondary mx-2"
                type="submit"
                disabled={isDispatched}
                onClick={changeAvatarHandler}
              >
                Change file
              </button>
            </div>
          </>
        ) : (
          renderInput({ setNewAvatar, setPreview })
        )}
      </form>
    </div>
  );
}

export default EditAvatarForm;
