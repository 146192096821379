// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components, assets, actions etc..
import CopyHandler from '../common/CopyHandler';

function ApiKeySecret(props) {
  const { t } = useTranslation();
  const { apiKey } = props;
  const { secretAccessKey } = apiKey;

  return (
    <div className="row mt-2 d-flex justify-content-center">
      <h5 className="text-danger my-3">{t('components.apiKeySecret.thisApiKey')}</h5>
      <CopyHandler toCopy={secretAccessKey} />
    </div>
  );
}

ApiKeySecret.propTypes = {
  apiKey: PropTypes.shape({
    secretAccessKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApiKeySecret;
