import React from 'react';
import hypercube from '../../api/hypercube';
import ApiKeySecret from '../../components/apiKeys/ApiKeySecret';
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  FEED_MODAL,
  FETCH_ALLOWED_PERMISSIONS,
  FETCH_API_KEYS,
  UPDATE_API_KEY,
} from '../types';

export const fetchApiKeys = () => async (dispatch) => {
  const res = await hypercube.get('/apiKeys');

  dispatch({ type: FETCH_API_KEYS, payload: res.data.apiKeys });
};
export const createApiKey = (options) => async (dispatch) => {
  try {
    const res = await hypercube.post('/apiKeys', options);
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'success',
        from: 'apiKeys',
        Details: () => <ApiKeySecret apiKey={res.data.apiKey} />,
        isNew: true,
      },
    });
    dispatch({ type: CREATE_API_KEY, payload: res.data.apiKey });
  } catch (error) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'error', from: 'apiKeys', err: 'Something went wrong.' },
    });
  }
};
export const updateApiKey = (accessKeyId, options) => async (dispatch) => {
  try {
    const res = await hypercube.patch(`/apiKeys/${accessKeyId}`, options);
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'success', from: 'apiKeys', apiKey: res.data.apiKeys, isNew: false,
      },
    });
    dispatch({ type: UPDATE_API_KEY, payload: res.data.apiKey });
  } catch (error) {
    dispatch({ type: FEED_MODAL, payload: { type: 'error', from: 'apiKeys', err: error } });
  }
};

export const deleteApiKey = (accessKeyId) => async (dispatch) => {
  await hypercube.delete(`/apiKeys/${accessKeyId}`);

  dispatch({ type: DELETE_API_KEY, payload: { accessKeyId } });
};

export const toggleApiKeyActive = (accessKeyId, active = true) => async (dispatch) => {
  const res = await hypercube.patch(`/apiKeys/${accessKeyId}`, { active });

  dispatch({ type: UPDATE_API_KEY, payload: res.data.apiKey });
};

export const fetchAllowedPermissions = () => async (dispatch) => {
  const res = await hypercube.get('/apiKeys/permissions');
  dispatch({ type: FETCH_ALLOWED_PERMISSIONS, payload: res.data });
};
