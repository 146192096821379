import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

function FormInput({
  label = '', name, errors, placeholder, inline = true, ...props
}) {
  return (
    <div className={inline ? '' : 'form-group'}>
      {
        label ? (
          <label htmlFor={name}>{label}</label>
        ) : null
      }
      <Field
        name={name}
        className={`form-control ${errors[name] && 'is-invalid'}`}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage name={name}>
        {(message) => <div className="invalid-feedback">{message}</div>}
      </ErrorMessage>
    </div>
  );
}

export default FormInput;

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};
