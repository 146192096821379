// Dependencies
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { connectIntegration } from '../../actions/dashboard/integrationsActions';
import useForm from '../../hooks/useForm';
import FormInput from './FormInput';

function IntegrationForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const { onSubmit } = useForm((body) => {
    dispatch(connectIntegration({ ...body, organizationId: currentOrganization._id }, 'gcp'));
  });

  const validateFields = (values) => Object.entries(values).reduce((errors, [key, val]) => {
    if (key !== 'description' && (!val || !val.trim())) return { ...errors, [key]: t('components.forms.googleIntegrationForm.required') };
    return errors;
  }, {});

  return (
    <div className="d-flex flex-row align-items-center justify-content-center mt-1 p-2">
      <Formik
        initialValues={{
          projectId: '',
          dataset: '',
          name: '',
          description: '',
          type: 'gcp',
        }}
        validate={validateFields}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className="row">
            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.googleIntegrationForm.name')}
                name="name"
                placeholder={t('components.forms.googleIntegrationForm.exName')}
                inline={false}
              />
            </div>
            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.googleIntegrationForm.projectId')}
                name="projectId"
                placeholder={t('components.forms.googleIntegrationForm.exProjectId')}
                inline={false}
              />
            </div>

            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.googleIntegrationForm.dataset')}
                name="dataset"
                placeholder={t('components.forms.googleIntegrationForm.exDataset')}
                inline={false}
              />
            </div>

            <div className="col-12">
              <FormInput
                errors={errors}
                label={t('components.forms.googleIntegrationForm.description')}
                name="description"
                placeholder={t('components.forms.googleIntegrationForm.exDescription')}
                component="textarea"
                rows="2"
                cols="80"
                inline={false}
              />
            </div>

            <div className="card-footer d-flex w-100 pb-2">
              <button
                type="submit"
                className="btn btn-primary col-md-2 ml-auto"
                disabled={isSubmitting}
              >
                {t('components.forms.googleIntegrationForm.connect')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default IntegrationForm;
