import hypercube from '../../api/hypercube';
import {
  FETCH_FINDING_RESOURCE,
  UPDATE_FINDING_RESOURCES,
  UPDATE_RESOURCE_BY_REGION,
  CURRENT_FINDING,
  FEED_MODAL,
} from '../types';

export const fetchLastResources = (properties) => async (dispatch) => {
  await dispatch({ type: CURRENT_FINDING, payload: properties.findingId });
  let { limit, pageNumber } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;

  const params = {
    limit,
    pageNumber,
    sortBy: 'updatedAt:desc',
    ...(properties.dataFilter && { dataFilter: properties.dataFilter }),
  };

  const res = await hypercube.get(`/recommendations/${properties?.findingId}/resources`, { params });

  if (properties.currentTab) {
    await dispatch({
      type: UPDATE_FINDING_RESOURCES,
      payload: res.data,
      currentTab: properties.currentTab,
    });
  } else {
    await dispatch({
      type: FETCH_FINDING_RESOURCE,
      payload: {
        data: res.data,
        type: properties.type,
      },
    });
  }
};

export const updateResourcesByRegion = (properties) => async (dispatch) => {
  await dispatch({ type: CURRENT_FINDING, payload: properties.findingId });
  let { limit, pageNumber } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;

  const params = {
    limit,
    pageNumber,
    sortBy: 'updatedAt:desc',
    ...(properties.dataFilter && { dataFilter: properties.dataFilter }),
  };

  const res = await hypercube.get(`recommendations/${properties.findingId}/resources`, { params });

  await dispatch({
    type: UPDATE_RESOURCE_BY_REGION,
    payload: res.data,
  });
};

export const archivedResource = (properties) => async (dispatch) => {
  try {
    const { id, archived, findingId } = properties;
    const res = await hypercube.patch(`/recommendations/${findingId}/resources/${id}`, { archived: !archived });
    return res.status === 200;
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'archiving resource', err },
    });
    return false;
  }
};
