// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteApiKey } from '../../actions/dashboard/apiKeysActions';
// Components, actions, assets..
import CloseModalButton from './CloseModalButton';

function DeleteApiKeyModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { content, closeModal } = props;

  const deleteHandler = async () => {
    await dispatch(deleteApiKey(content.accessKeyId));
    closeModal();
  };

  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column col">
          <h6 className="header-pretitle text-center">{t('components.modals.deleteApiKey.yourApi')}</h6>
          <h1 className="header-title text-center">{t('components.modals.deleteApiKey.deleteApiKey')}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-5">
          <h4 className="text-center">{t('components.modals.deleteApiKey.areYouSure')}</h4>

          <h4 className="text-center">
            {t('components.modals.deleteApiKey.thisOperation')}
            <strong>
              {t('components.modals.deleteApiKey.cannotBe')}
            </strong>
          </h4>
        </div>
        <div className="row justify-content-center pt-4">
          <button type="submit" className="btn btn-danger" onClick={deleteHandler}>
            {t('components.modals.deleteApiKey.deleteApiKey')}
          </button>
        </div>
      </div>
    </>
  );
}

DeleteApiKeyModal.propTypes = {
  content: PropTypes.shape({
    accessKeyId: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteApiKeyModal;
