/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipCustom(props) {
  const { children, text, placement = 'top' } = props;

  return (
    <OverlayTrigger
      propTypes
      delay={{ show: 250, hide: 400 }}
      placement={placement}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
TooltipCustom.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

export default TooltipCustom;
