// Dependencies
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, assets, actions, etc..
import { editUser } from '../../actions/userActions';
import FormInput from './FormInput';

const dateExp = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/);
const phoneExp = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);

function UserEditForm(props) {
  const { t } = useTranslation();
  const { user } = props;
  const dispatch = useDispatch();

  const update = (values, { setSubmitting }) => {
    dispatch(editUser(values));
    setSubmitting(true);
  };

  const validator = (values) => {
    const errors = {};
    if (!values.givenName.trim()) {
      errors.givenName = t('components.forms.editUserForm.fieldRequired');
    }
    if (!values.familyName.trim()) {
      errors.familyName = t('components.forms.editUserForm.fieldRequired');
    }

    if (values.birthDate && !dateExp.test(values.birthDate)) {
      errors.birthDate = t('components.forms.editUserForm.invalidDateFormat');
    }

    if (values.phoneNumber && !phoneExp.test(values.phoneNumber)) {
      errors.phoneNumber = t('components.forms.editUserForm.invalidPhoneNumber');
    }

    return errors;
  };

  return (
    <div className="container-fluid pt-5">
      <div className="col">
        <h6 className="header-pretitle text-center">{t('components.forms.editUserForm.yourAccount')}</h6>
        <h1 className="header-title text-center">{t('components.forms.editUserForm.updateInfo')}</h1>
      </div>
      <Formik
        validate={validator}
        initialValues={{
          givenName: user.givenName || '',
          familyName: user.familyName || '',
          phoneNumber: '',
          birthDate: '',
        }}
        onSubmit={update}
      >
        {({ isSubmitting, errors }) => (
          <Form className="form-group row">
            <div className="row pt-4 px-4">
              <div className="col-sm-12 col-md-6 mb-3">
                <FormInput
                  errors={errors}
                  label={t('components.forms.editUserForm.firstName')}
                  name="givenName"
                  placeholder={user.givenName}
                />
              </div>

              <div className="col-sm-12 col-md-6 mb-3">
                <FormInput
                  errors={errors}
                  label={t('components.forms.editUserForm.lastName')}
                  name="familyName"
                  placeholder={user.familyName}
                />
              </div>
              <div className="col-sm-12 col-md-6 mb-3">
                <FormInput
                  errors={errors}
                  label={t('components.forms.editUserForm.phoneNumber')}
                  name="phoneNumber"
                  placeholder="(XXX)-XXX-XX-XX"
                />
              </div>

              <div className="col-sm-12 col-md-6 mb-3">
                <FormInput
                  errors={errors}
                  label={t('components.forms.editUserForm.birthDate')}
                  name="birthDate"
                  placeholder="MM/DD/YY"
                />
              </div>

              <div className="col-12 d-flex justify-content-center pt-4">
                <button disabled={isSubmitting} className="btn btn-success" type="submit">
                  {t('components.forms.editUserForm.submit')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UserEditForm;

UserEditForm.propTypes = {
  user: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
};
