/* eslint-disable react/require-default-props */
// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components, assets, actions, etc..
import FillerImg from '../../images/backgrounds/filler.svg';

function HomeFiller({ to = 'integrations' }) {
  const { t } = useTranslation();
  return (
    <div className="container-fluid py-5 my-3 px-0 mx-0 row bg-light rounded" id="home-filler-container">
      <div className="col-md-12 text-center text-md-left">
        <h1 className="display-3 text-center mb-4">
          {t('components.fillers.homeFiller.welcomeTo')}
          <span className="text-primary"> Obok </span>
        </h1>
        <p className="font-size-lg text-center">
          {t('components.fillers.homeFiller.realtime')}
        </p>
      </div>
      <div className="col-md-12 text-center">
        <img className="svg-helper" src={FillerImg} alt="Filler" />
      </div>
      <div className="col-md-12 text-center">
        <p className="font-size-lg text-center mb-1">
          {t('components.fillers.homeFiller.youMight')}
        </p>
        <p className="font-size-lg text-center">
          {t('components.fillers.homeFiller.letsBegin')}
        </p>
        <Link className="btn btn-primary btn-sm" to={to} id="get-started-button">
          {t('components.fillers.homeFiller.getStarted')}
        </Link>
      </div>
    </div>
  );
}

HomeFiller.propTypes = {
  to: PropTypes.string,
};

export default HomeFiller;
