import hypercube from '../../api/hypercube';
import {
  FETCH_NOTIFICATIONS, FETCH_NOTIFICATIONS_EDGES, FETCH_LATEST_NOTIFICATIONS, TOGGLE_MODAL,
} from '../types';

export const fetchNotifications = (properties = {}) => async (dispatch) => {
  let { limit, pageNumber, dataFilter } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  const rs = `/notifications?limit=${limit}&pageNumber=${pageNumber}&organizationId=${properties.organizationId}`;

  const res = await hypercube.get(rs, { params: { dataFilter, sortBy: 'createdAt:desc' } });
  dispatch({ type: FETCH_NOTIFICATIONS, payload: res.data });
};

export const fetchNotificationsEdges = (properties) => async (dispatch) => {
  try {
    const res = await hypercube.get(`/notifications/edges?organizationId=${properties.organizationId}`);
    dispatch({ type: FETCH_NOTIFICATIONS_EDGES, payload: res.data });
  } catch (err) {
    dispatch({ type: TOGGLE_MODAL, payload: { type: 'fail', from: 'notifications', err } });
  }
};

export const fetchLatestNotifications = (properties) => async (dispatch) => {
  try {
    const { organizationId, latest } = properties;
    const res = await hypercube.get(`/notifications/latest/${latest}?organizationId=${organizationId}`);
    dispatch({ type: FETCH_LATEST_NOTIFICATIONS, payload: res.data });
  } catch (err) {
    dispatch({ type: TOGGLE_MODAL, payload: { type: 'fail', from: 'notifications', err } });
  }
};

export const readAllNotifications = (properties) => async (dispatch) => {
  try {
    const { organizationId } = properties;

    await hypercube.patch(`/notifications/readAll?organizationId=${organizationId}`);
  } catch (err) {
    dispatch({ type: TOGGLE_MODAL, payload: { type: 'fail', from: 'notifications', err } });
  }
};

export const readNotifications = (properties) => async (dispatch) => {
  try {
    const { seenNotifications } = properties;

    await hypercube.patch('/notifications/seen', seenNotifications);
  } catch (err) {
    dispatch({ type: TOGGLE_MODAL, payload: { type: 'fail', from: 'notifications', err } });
  }
};
