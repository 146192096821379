const INITIAL_STATE = [
  {
    name: 'Account',
    linkTo: 'settings',
    icon: 'far fa-user',
    sublinks: [
      {
        name: 'Back',
        linkTo: 'back',
      },
      {
        name: 'General',
        linkTo: '/dashboard/profile',
        icon: 'fas fa-cogs',
        sublinks: false,
      },
      {
        name: 'API',
        linkTo: '/dashboard/api',
        icon: 'fas fa-file-alt',
        sublinks: false,
      },
      {
        name: 'Notifications',
        linkTo: '/dashboard/notifications',
        icon: 'far fa-bell',
        sublinks: false,
      },
      {
        name: 'Billing',
        linkTo: '/dashboard/billing',
        sublinks: false,
      },
    ],
  },
  {
    name: 'Dashboard',
    linkTo: '/dashboard',
    icon: 'fas fa-home',
    sublinks: false,
  },
  {
    name: 'Integrations',
    linkTo: '/dashboard/integrations',
    icon: 'fas fa-plug',
    sublinks: false,
  },
  {
    name: 'Savings',
    linkTo: '/dashboard/savings',
    icon: 'fas fa-piggy-bank',
    sublinks: false,
  },
  {
    name: 'Security',
    linkTo: '/dashboard/security',
    icon: 'fas fa-shield-alt',
    sublinks: false,
  },
  {
    name: 'Transactions',
    linkTo: '/dashboard/transactions',
    icon: 'fas fa-book-open',
    sublinks: false,
  },
  {
    name: 'Organizations',
    linkTo: '/dashboard/organizations',
    icon: 'fa fa-building',
    sublinks: false,
  },
  {
    name: 'Sign Out',
    linkTo: '/signout',
    sublinks: false,
  },
];

export default function routerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
