// Dependencies
import { Form, Formik, Field } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, assets, actions, etc..
import useForm from '../../../hooks/useForm';

function ChangeMemberRoleForm(props) {
  const { t } = useTranslation();
  const {
    organizationId, memberId, modalAction,
  } = props;

  const dispatch = useDispatch();

  const { onSubmit } = useForm(async (body) => {
    const data = {
      ...body,
      organizationId,
      memberId,
    };
    await dispatch(modalAction(data));
  });

  const role = 'guest';

  return (
    <div className="d-flex justify-content-center mt-1 pr-5 pl-5 pt-3 pb-3">
      <Formik
        initialValues={{
          role,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className="p-0 w-100">
            <div className="row">
              <div className="col-12 mb-3">
                <Field className="custom-select" name="role" as="select">
                  <option value="guest">Guest</option>
                  <option value="admin">Admin</option>
                </Field>
              </div>
            </div>
            <div className="card-footer d-flex w-100 pb-2">
              <button
                type="submit"
                className="btn btn-primary ml-auto"
              >
                {t('components.forms.organizations.changeMemberRoleForm.change')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ChangeMemberRoleForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  modalAction: PropTypes.func.isRequired,
};

export default ChangeMemberRoleForm;
