// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';
import {
  deleteOrganization,
  switchCurrentOrganization,
} from '../../../actions/organization/organizationActions';
import { reloadUser } from '../../../actions/userActions';

function DeleteOrganizationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const { content, closeModal } = props;
  const { organization } = content;

  const deleteHandler = async () => {
    await dispatch(deleteOrganization(organization._id));
    await dispatch(reloadUser());
    await dispatch(switchCurrentOrganization(organization, user));
    closeModal();
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={organization.brandLogoUrl} className="img-fluid mb-2" alt="Logo" height="50" width="50" />
          <h6 className="header-pretitle text-center">
            {`${t('components.modals.organizations.deleteOrganizationModal.yourOrganization')} ${organization.name}`}
          </h6>
          <h1 className="header-title text-center">{t('components.modals.organizations.deleteOrganizationModal.title')}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-3">
          <h4 className="text-center">{t('components.modals.organizations.deleteOrganizationModal.areYouSure')}</h4>

          <h4 className="text-center">
            {t('components.modals.organizations.deleteOrganizationModal.thisOperation')}
            <strong>{t('components.modals.organizations.deleteOrganizationModal.cannotBe')}</strong>
          </h4>
        </div>
        <div className="row justify-content-center pt-4">
          <button type="submit" className="btn btn-danger" onClick={deleteHandler}>
            {t('components.modals.organizations.deleteOrganizationModal.delete')}
          </button>
        </div>
      </div>
    </>
  );
}

DeleteOrganizationModal.propTypes = {
  content: PropTypes.shape({
    organization: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.bool,
      brandLogoUrl: PropTypes.string,
      owner: PropTypes.string,
    }),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteOrganizationModal;
