/* eslint-disable max-len */
import hypercube from '../../api/hypercube';
import { FETCH_TRANSACTIONS, FETCH_TOTAL_SPENDING } from '../types';
import { parseToExactRegionDate } from '../../helpers/date';

export const fetchSpendingTotal = (properties) => async (dispatch) => {
  let requestString = `/transactions/spendings/total?currency=USD&organizationId=${properties.organizationId}`;
  const filters = properties?.filters || null;

  if (filters?.start && filters?.end) {
    requestString += `&filters[start]=${encodeURIComponent(parseToExactRegionDate(filters.start))}`;
    requestString += `&filters[end]=${encodeURIComponent(parseToExactRegionDate(filters.end))}`;

    delete filters?.start;
    delete filters?.end;
  }

  const res = await hypercube.get(requestString, {
    params: {
      ...(filters && {
        ...(filters.organizationId && {
          organizationId: filters.organizationId,
        }),
        filters,
      }),
    },
  });

  dispatch({ type: FETCH_TOTAL_SPENDING, payload: res.data });
};

export const fetchTransactions = (properties) => async (dispatch) => {
  let url = '/transactions?sortBy=invoiceTimestamp:desc';
  let {
    limit, pageNumber, dataFilter,
  } = properties;

  const { organizationId } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  if (dataFilter?.start && dataFilter?.end) {
    url += `&dataFilter[start]=${encodeURIComponent(parseToExactRegionDate(dataFilter.start, true))}`;
    url += `&dataFilter[end]=${encodeURIComponent(parseToExactRegionDate(dataFilter.end))}`;
    const { start, end, ...filteredDataFilter } = dataFilter;
    dataFilter = filteredDataFilter;
  }

  const res = await hypercube.get(
    url,
    {
      params: {
        dataFilter,
        limit,
        pageNumber,
        organizationId,
      },
    },
  );
  dispatch({ type: FETCH_TRANSACTIONS, payload: res.data });
};
