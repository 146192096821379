const WebFont = require('webfontloader');

const fonts = () => {
  WebFont.load({
    google: {
      families: ['Source Sans Pro', 'Roboto'],
    },
  });
};

export default fonts();
