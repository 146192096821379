// Dependencies
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// Components, assets, actions etc..
import { toggleModal } from '../../actions/modalActions';
import ApiKeyModal from './ApiKeyModal';
import ArchiveFindingModal from './ArchiveFindingModal';
import ArchiveResourceModal from './ArchiveResourceModal';
import ApiKeyPermissionsModal from './ApiKeyPermissionsModal';
import DeleteApiKeyModal from './DeleteApiKeyModals';
import DeleteIntegration from './integrations/DeleteIntegration';
import DeleteFindingModal from './DeleteFindingModal';
import DeleteUserModal from './DeleteUserModal';
import DisableIntegration from './integrations/DisableIntegration';
import EditAvatarModal from './EditAvatarModal';
import EditIntegrationModal from './integrations/EditIntegration';
import EditUserModal from './EditUserModal';
import FailureModal from './FailureModal';
import IntegrationModalWrapper from './integrations/IntegrationModalWrapper';
import ResourcesModal from './ResourcesModal';
import SavingsModal from './SavingsModal';
import ApplyModal from './ApplyModal';
import SuccessModal from './SuccessModal';
import KnowledgeBaseModal from './KnowledgeBaseModal';
import DeleteOrganizationModal from './organizations/DeleteOrganizationModal';
import AddOrganizationModal from './organizations/AddOrganizationModal';
import DeleteMemberInvitationModal from './organizations/DeleteMemberInvitationModal';
import AddInvitationMemberModal from './organizations/AddMemberModal';
import CurrentOrganizationModal from './organizations/CurrentOrganizationModal';
import LeaveOrganizationModal from './organizations/LeaveOrganizationModal';
import ChangeRoleModal from './organizations/ChangeMemberRoleModal';
import LoadingModal from './LoadingModal';

function ModalWrapper() {
  const {
    active, content, fullscreen, Details,
  } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleModal('close'));
  };

  const renderModal = () => {
    switch (content?.type) {
      case 'integrationWrapper':
        return <IntegrationModalWrapper content={content} closeModal={closeModal} />;
      case 'saving':
        return <SavingsModal content={content} closeModal={closeModal} />;
      case 'success':
        return (
          <SuccessModal content={content} closeModal={closeModal}>
            <Details />
          </SuccessModal>
        );
      case 'fail':
        return <FailureModal content={content} closeModal={closeModal} />;
      case 'editUser':
        return <EditUserModal content={content} closeModal={closeModal} />;
      case 'changeAvatar':
        return <EditAvatarModal closeModal={closeModal} />;
      case 'deleteUser':
        return <DeleteUserModal closeModal={closeModal} />;
      case 'deleteSaving':
        return <DeleteFindingModal content={content} closeModal={closeModal} />;
      case 'resources':
        return <ResourcesModal content={content} closeModal={closeModal} />;
      case 'applyModal':
        return <ApplyModal content={content} closeModal={closeModal} />;
      case 'apiKey':
        return <ApiKeyModal content={content} closeModal={closeModal} />;
      case 'apiKeyPermissions':
        return <ApiKeyPermissionsModal content={content} closeModal={closeModal} />;
      case 'apiKeyDelete':
        return <DeleteApiKeyModal content={content} closeModal={closeModal} />;
      case 'editIntegration':
        return <EditIntegrationModal content={content} closeModal={closeModal} />;
      case 'disableIntegration':
        return <DisableIntegration content={content} closeModal={closeModal} />;
      case 'deleteIntegration':
        return <DeleteIntegration content={content} closeModal={closeModal} />;
      case 'knowledgeBaseModal':
        return <KnowledgeBaseModal content={content} closeModal={closeModal} />;
      case 'deleteOrganization':
        return <DeleteOrganizationModal content={content} closeModal={closeModal} />;
      case 'addOrganization':
        return <AddOrganizationModal content={content} closeModal={closeModal} />;
      case 'deleteMemberInvitation':
        return <DeleteMemberInvitationModal content={content} closeModal={closeModal} />;
      case 'addInvitationMember':
        return <AddInvitationMemberModal content={content} closeModal={closeModal} />;
      case 'currentOrganization':
        return <CurrentOrganizationModal content={content} closeModal={closeModal} />;
      case 'leaveOrganization':
        return <LeaveOrganizationModal content={content} closeModal={closeModal} />;
      case 'changeRole':
        return <ChangeRoleModal content={content} closeModal={closeModal} />;
      case 'archiveFinding':
        return <ArchiveFindingModal content={content} closeModal={closeModal} />;
      case 'archiveResource':
        return <ArchiveResourceModal content={content} closeModal={closeModal} />;
      case 'loading':
        return <LoadingModal content={content} closeModal={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={active}
      size="lg"
      fullscreen={fullscreen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => (content?.type !== 'loading' ? closeModal() : true)}
    >
      {active ? renderModal() : null}
    </Modal>
  );
}

export default ModalWrapper;
