// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components, actions, assets, etc..
import TimeAgo from '../widgets/TimeAgo';

function NotificationItem(props) {
  const { title, notification, icon } = props;
  const { description, createdAt } = notification;

  return (
    <Link to="/dashboard/notifications" className="text-dark">
      <div className="list-group-item cursor-pointer">
        <div className="row">
          <div className="col-auto">
            <div className="avatar avatar-sm avatar-online">
              <img className="avatar-img rounded-circle" src={icon} alt="User" />
            </div>
          </div>
          <div className="col ml-n2">
            <h5 className="mb-1">{title}</h5>
            <p className="small text-gray-700 mb-0">{description}</p>
            <p className="text-muted text-first-letter-capitalize">
              <small>{TimeAgo(createdAt)}</small>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default NotificationItem;

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  notification: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};
