import React from 'react';
import PropTypes from 'prop-types';

function EmptyData({ height }) {
  return (
    <div
      className="empty-data-container"
      style={{
        height,
      }}
    >
      <p className="text-muted">No data available</p>
    </div>
  );
}

EmptyData.propTypes = {
  height: PropTypes.string.isRequired,
};

export default EmptyData;
