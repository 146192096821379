// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
// Components, assets, actions etc..
import { toggleModal } from '../../actions/modalActions';
import CloseModalButton from './CloseModalButton';

function SavingsModal(props) {
  const dispatch = useDispatch();
  const { content, closeModal } = props;

  const renderModalContent = (items) => items.map((item) => (
    <li className="m-0 py-1 px-1" key={item}>
      {' '}
      {parse(item)}
      {' '}
    </li>
  ));

  const renderResourcesModal = () => {
    closeModal();
    dispatch(toggleModal({
      icon: content.icon,
      savingId: content.savingId,
      vendor: content.vendor,
      title: content.title,
      type: 'resources',
      instructions: content.instructions,
      navigation: true,
    }));
  };

  const renderApplyModal = () => {
    closeModal();
    dispatch(toggleModal({
      icon: content.icon,
      instructions: content.instructions,
      savingType: content.savingType,
      savingId: content.savingId,
      vendor: content.vendor,
      type: 'applyModal',
      navigation: true,
    }));
  };

  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <img src={content.icon} alt="Logo" height="50" width="50" />
            <h6 className="header-pretitle mb-1">Instructions:</h6>
            <h1 className="header-title mb-4">{content.vendor}</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3">
        {content.instructions.length >= 1 ? (
          <ol className="m-0">{renderModalContent(content.instructions)}</ol>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <h2 className="header-title mb-4">
              No instructions available for
              {content.title}
            </h2>
          </div>
        )}
      </div>
      <div className="card-footer d-flex mt-4">
        <div className="ml-auto">
          <button className="btn btn-primary" type="button" onClick={() => renderApplyModal()}>
            Apply Changes
          </button>
          {' '}
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => renderResourcesModal()}
          >
            Affected resources
          </button>
        </div>
      </div>
    </>
  );
}

export default SavingsModal;

SavingsModal.propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
    savingId: PropTypes.string,
    title: PropTypes.string,
    savingType: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
