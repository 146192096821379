import { FETCH_INSIGHTS } from '../../actions/types';

const INITIAL_STATE = null;

export default function insightsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INSIGHTS:
      return action.payload;
    default:
      return state;
  }
}
