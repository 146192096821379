// Dependencies
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-bootstrap/Collapse';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch, useSelector } from 'react-redux';
import { createApiKey, toggleApiKeyActive, updateApiKey } from '../../actions/dashboard/apiKeysActions';

const copyPermissions = (permissions) => ({
  ...Object.entries(permissions).reduce(
    (prev, [key, value]) => ({ ...prev, [key]: [...value] }),
    {},
  ),
});

function ApiKeyForm(props) {
  const { t } = useTranslation();
  const { accessKeyId, closeModal } = props;
  const apiKey = useSelector(
    (state) => state.apiKeys.keys.find((key) => key.accessKeyId === accessKeyId),
  );
  const allowedPermissions = useSelector(
    (state) => state.apiKeys.allowedPermissions,
  );

  const [permissions, setPermissions] = useState(
    apiKey?.permissions ? copyPermissions(apiKey.permissions) : {},
  );
  const [name, setName] = useState(apiKey?.name || '');
  const [invalid, setInvalid] = useState(new Set([]));
  const dispatch = useDispatch();

  const enableFeatureHandler = (feature) => {
    setPermissions((pr) => {
      const prs = { ...pr };
      const isActive = prs[feature];

      if (isActive) delete prs[feature];
      else prs[feature] = [...allowedPermissions[feature]];

      return prs;
    });
  };

  const enablePermissionHandler = (feature, method) => {
    setPermissions((p) => {
      const prs = { ...p };

      const idxMethod = prs[feature].indexOf(method);

      if (idxMethod !== -1) prs[feature].splice(idxMethod, 1);
      else prs[feature].push(method);

      if (!prs[feature].length) delete prs[feature];

      return prs;
    });
  };

  const saveApiKeyHandler = async (e) => {
    e.preventDefault();
    const invalidates = [];
    if (!name || name.trim() === '') invalidates.push('name');
    const areInValidPermissions = Object.values(permissions).length === 0;

    if (areInValidPermissions) invalidates.push('permissions');

    if (!invalidates.length) {
      dispatch(
        !accessKeyId
          ? createApiKey({ permissions, name })
          : updateApiKey(accessKeyId, { permissions, name }),
      );
    }

    return setInvalid(new Set(invalidates));
  };

  return (
    <div className="container-fluid pt-5">
      <div className="col">
        <h6 className="header-pretitle text-center">{t('components.forms.api.apis')}</h6>
        <h1 className="header-title text-center">{t('components.forms.api.configuration')}</h1>
        {invalid.has('permissions') && (
          <p className="text-danger text-center pt-2">
            <i className="fas fa-exclamation-triangle mr-1" />
            {t('components.forms.api.pleaseSelect')}
          </p>
        )}
      </div>
      <form onSubmit={saveApiKeyHandler}>
        <div className="mx-6 my-4 p-2 overflow-auto" style={{ height: '18rem' }}>
          <InputGroup className="input-group mb-4">
            <InputGroup.Text id="apikeyName">{t('components.forms.api.apiKeyName')}</InputGroup.Text>
            <FormControl
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={invalid.has('name')}
              aria-describedby="apiKeyName"
            />
            <div className="invalid-feedback">{t('components.forms.api.pleaseEnter')}</div>
          </InputGroup>
          {Object.entries(allowedPermissions).map(([feature, allowed]) => {
            const isActive = accessKeyId !== null
              ? permissions[feature]?.length > 0 : !!permissions[feature];
            return (
              <div key={feature}>
                <PermissionInput
                  permission={feature}
                  isSelected={isActive}
                  key={feature}
                  onClick={() => enableFeatureHandler(feature)}
                />
                <Collapse in={isActive} className="px-5 py-2">
                  <div>
                    {allowed.map((method) => (
                      <PermissionInput
                        permission={method}
                        isSelected={permissions[feature]?.indexOf(method) !== -1}
                        key={feature + method}
                        onClick={() => enablePermissionHandler(feature, method)}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            );
          })}
        </div>

        <div className="col-12 d-flex justify-content-center pt-4">
          {accessKeyId !== null && (
            <button
              className="btn btn-warning mr-2"
              type="button"
              onClick={
                () => dispatch(toggleApiKeyActive(accessKeyId, !apiKey.active)) && closeModal()
              }
            >
              {t('components.forms.api.disable')}
            </button>
          )}
          <button className="btn btn-success" type="submit">
            {t('components.forms.api.generate')}
          </button>
        </div>
      </form>
    </div>
  );
}

function PermissionInput({ permission, isSelected, onClick }) {
  return (
    <InputGroup className="input-group-sm">
      <InputGroup.Checkbox onClick={onClick} checked={isSelected} readOnly />
      <FormControl value={`${permission}`} disabled />
    </InputGroup>
  );
}

export default ApiKeyForm;

ApiKeyForm.propTypes = {
  accessKeyId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

ApiKeyForm.defaultProps = {
  accessKeyId: null,
};
