// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { JSONTree } from 'react-json-tree';

// Components, actions, assets..
import CloseModalButton from './CloseModalButton';

function ApiKeyPermissionsModal(props) {
  const { content, closeModal } = props;

  const theme = {
    scheme: 'Google',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#1d1f21',
    base01: '#282a2e',
    base02: '#373b41',
    base03: '#969896',
    base04: '#b4b7b4',
    base05: '#c5c8c6',
    base06: '#e0e0e0',
    base07: '#ffffff',
    base08: '#CC342B',
    base09: '#F96A38',
    base0A: '#FBA922',
    base0B: '#198844',
    base0C: '#2C7BE5',
    base0D: '#2C7BE5',
    base0E: '#A36AC7',
    base0F: '#2C7BE5',
  };

  return (
    <>
      <div className="modal-header border-0 justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center">Your ApiKey</h6>
            <h1 className="header-title text-center">Permissions</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body p-3 container-fluid mb-5">
        <div
          className="col pt-5 mx-auto"
          style={{ height: '18rem', overflow: 'scroll', overflowX: 'hidden' }}
        >
          <JSONTree className="mx-auto" data={content.permissions} theme={theme} />
        </div>
      </div>
    </>
  );
}

ApiKeyPermissionsModal.propTypes = {
  content: PropTypes.shape({
    permissions: PropTypes.shape({}),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ApiKeyPermissionsModal;
