import { FETCH_SAVINGS, FETCH_SAVINGS_EDGES, SET_SAVINGS_STATE } from '../../actions/types';

const INITIAL_STATE = {
  loading: true,
  error: '',
  all: [],
  totals: {},
};

export default function savingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SAVINGS_STATE:
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
      };
    case FETCH_SAVINGS:
      return {
        ...state,
        all: action.payload.result,
      };
    case FETCH_SAVINGS_EDGES:
      return {
        ...state,
        totals: {
          ...action.payload.totals,
        },
      };
    default:
      return state;
  }
}
