const useForm = (fetch) => {
  const onSubmit = (body, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    fetch(body).catch((errors) => {
      setSubmitting(false);
      errors.forEach(({ msg, param }) => setFieldError(param.split('.').pop(), msg));
    });
  };
  return { onSubmit };
};

export default useForm;
