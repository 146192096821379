/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { vendors } from '../../constants/clouds';
import EmptyData from '../common/emptyData';

function CurrentSpendingBarChart(props) {
  const { data } = props;
  const labels = data.lastThreeMonthsTransactions ? data.lastThreeMonthsTransactions.monthNames : null;
  const currentMonthSpending = data.lastThreeMonthsTransactions ? data.lastThreeMonthsTransactions?.currentMonthSpending : [];

  const dataConf = {
    labels,
    datasets: currentMonthSpending,
  };

  const isEmptyData = currentMonthSpending.every((currentMonth) => currentMonth?.data.every((spendings) => spendings <= 0));

  if (isEmptyData) {
    return <EmptyData height="8rem" />;
  }
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback(value) {
            return `$ ${value.toLocaleString()}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          generateLabels(chart) {
            return data.lastThreeMonthsTransactions.vendorsNames.map((vendor, i) => ({
              text: vendor,
              fillStyle: data.lastThreeMonthsTransactions.colors[i],
              lineWidth: 1,
              hidden: false,
              index: i,
            }));
          },
        },
      },
      tooltip: {
        callbacks: {
          title(context) {
            const { datasetIndex } = context[0];
            if (datasetIndex === 0) {
              return vendors.AWS;
            }
            if (datasetIndex === 1) {
              return vendors.Azure;
            }
            if (datasetIndex === 2) {
              return vendors.GCP;
            }
            if (datasetIndex === 3) {
              return vendors.OCI;
            }

            return '';
          },
          label(context) {
            const { datasetIndex } = context;
            const index = context.dataIndex;
            const value = currentMonthSpending[datasetIndex].data[index];

            return `${Math.floor(value).toLocaleString()}`;
          },
        },
      },
    },
  };
  return <Chart type="bar" data={dataConf} options={options} height={350} />;
}

CurrentSpendingBarChart.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    lastThreeMonthsTransactions: PropTypes.object,
  }).isRequired,
};

export default CurrentSpendingBarChart;
