// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components, actions, assets..
import CloseModalButton from './CloseModalButton';
import { archivedSaving, fetchSavings } from '../../actions/dashboard/savingsActions';
import { archivedSecurity, fetchSecurities } from '../../actions/dashboard/securityActions';

function ArchiveFindingModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { content, closeModal } = props;
  const {
    dataFilter,
    icon,
    findingType,
    id,
    archived,
  } = content;

  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const archiveHandler = async () => {
    let isArchived;
    if (findingType === 'Saving') {
      isArchived = await dispatch(archivedSaving({ id, archived }));
    } else {
      isArchived = await dispatch(archivedSecurity({ id, archived }));
    }

    if (isArchived) {
      if (findingType === 'Saving') {
        await dispatch(fetchSavings({ dataFilter, organizationId: currentOrganization._id }));
      } else {
        await dispatch(fetchSecurities({ dataFilter, organizationId: currentOrganization._id }));
      }
      closeModal();
    }
  };

  const savingOrSecurityHandler = () => {
    if (!archived) {
      if (findingType === 'Saving') {
        return {
          title: t('components.modals.archiveFindingModal.savingsArchiveTitle'),
          subtitle: t('components.modals.archiveFindingModal.savingsArchiveSubtitle'),
        };
      }
      return {
        title: t('components.modals.archiveFindingModal.securityArchiveTitle'),
        subtitle: t('components.modals.archiveFindingModal.securityArchiveSubtitle'),
      };
    }
    if (findingType === 'Saving') {
      return {
        title: t('components.modals.archiveFindingModal.savingsUnarchiveTitle'),
        subtitle: t('components.modals.archiveFindingModal.savingsUnarchiveSubtitle'),
      };
    }
    return {
      title: t('components.modals.archiveFindingModal.securityUnarchiveTitle'),
      subtitle: t('components.modals.archiveFindingModal.securityUnarchiveSubtitle'),
    };
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={icon} alt="Logo" height="50" width="50" />
          <h6 className="header-pretitle text-center">
            {(findingType === 'Saving') ? t('components.modals.archiveFindingModal.savingsPretitle') : t('components.modals.archiveFindingModal.securitiesPretitle')}
          </h6>
          <h1 className="header-title text-center">
            {savingOrSecurityHandler().title}
          </h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column align-items-center p-3">
        <div className="col pt-3">
          <h4 className="text-center">
            {savingOrSecurityHandler().subtitle}
          </h4>
        </div>
        <div className="row pt-4">
          <button type="submit" className={(archived) ? 'btn btn-success' : 'btn btn-danger'} onClick={archiveHandler}>
            {(archived) ? t('components.modals.archiveFindingModal.unarchive') : t('components.modals.archiveFindingModal.archive') }
          </button>
        </div>
      </div>
    </>
  );
}

ArchiveFindingModal.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    findingType: PropTypes.string,
    icon: PropTypes.string,
    archived: PropTypes.bool,
    dataFilter: PropTypes.shape({}),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ArchiveFindingModal;
