import { FEED_MODAL, TOGGLE_MODAL } from '../actions/types';

function Details() {
  return null;
}

const INITIAL_STATE = {
  active: false,
  fullscreen: false,
  content: null,
  Details,
};

export default function modalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        active: !state.active,
        content: action.payload,
        fullscreen: action.fullscreen,
      };
    case FEED_MODAL:
      return {
        ...state,
        content: action.payload,
        Details: action.payload.Details || Details,
      };
    default:
      return state;
  }
}
