// Dependencies
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { connectIntegration } from '../../actions/dashboard/integrationsActions';
import useForm from '../../hooks/useForm';
import FormInput from './FormInput';

const initialValues = {
  name: '',
  userId: '',
  tenancyId: '',
  compartmentId: '',
  fingerprint: '',
  privateKey: '',
  password: '',
  region: '',
  tenancyName: '',
  profileName: '',
};

function IntegrationForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const { onSubmit } = useForm((body) => {
    dispatch(connectIntegration({ ...body, organizationId: currentOrganization._id }, 'oracle'));
  });

  const validateFields = (values) => Object.entries(values).reduce((errors, [key, val]) => {
    if ((!val || !val.trim())) return { ...errors, [key]: t('components.forms.googleIntegrationForm.required') };
    return errors;
  }, {});

  return (
    <div className="d-flex flex-row align-items-center justify-content-center mt-1 p-2">
      <Formik
        initialValues={initialValues}
        validate={validateFields}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className="row">
            <div className="col-12">
              <FormInput
                errors={errors}
                label={`Integration ${t('components.forms.googleIntegrationForm.name')}`}
                name="name"
                placeholder={t('components.forms.googleIntegrationForm.name')}
                inline={false}
              />
            </div>
            <div className="col-6">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.tenancyName')}
                name="tenancyName"
                placeholder={t('components.forms.oracleIntegrationForm.tenancyName')}
                inline={false}
              />
            </div>
            <div className="col-6">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.profileName')}
                name="profileName"
                placeholder={t('components.forms.oracleIntegrationForm.profileName')}
                inline={false}
              />
            </div>
            <div className="col-6">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.userId')}
                name="userId"
                placeholder={t('components.forms.oracleIntegrationForm.userId')}
                inline={false}
              />
            </div>
            <div className="col-6">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.tenancyId')}
                name="tenancyId"
                placeholder={t('components.forms.oracleIntegrationForm.tenancyId')}
                inline={false}
              />
            </div>
            <div className="col-8">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.compartmentId')}
                name="compartmentId"
                placeholder={t('components.forms.oracleIntegrationForm.compartmentId')}
                inline={false}
              />
            </div>
            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.region')}
                name="region"
                placeholder={t('components.forms.oracleIntegrationForm.region')}
                inline={false}
              />
            </div>

            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.fingerprint')}
                name="fingerprint"
                placeholder={t('components.forms.oracleIntegrationForm.fingerprint')}
                inline={false}
              />
            </div>
            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.privateKey')}
                name="privateKey"
                placeholder={t('components.forms.oracleIntegrationForm.privateKey')}
                inline={false}
              />
            </div>
            <div className="col-4">
              <FormInput
                errors={errors}
                label={t('components.forms.oracleIntegrationForm.password')}
                name="password"
                placeholder={t('components.forms.oracleIntegrationForm.password')}
                inline={false}
              />
            </div>

            <div className="card-footer d-flex w-100 pb-2">
              <button
                type="submit"
                className="btn btn-primary col-md-2 ml-auto"
                disabled={isSubmitting}
              >
                {t('components.forms.googleIntegrationForm.connect')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default IntegrationForm;
