import { FETCH_TRANSACTIONS, FETCH_TOTAL_SPENDING } from '../../actions/types';

const INITIAL_STATE = null;

export default function transactionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case FETCH_TOTAL_SPENDING:
      return { ...state, total: action.payload };
    default:
      return state;
  }
}
