import {
  FETCH_FINDING_RESOURCE,
  UPDATE_FINDING_RESOURCES,
  UPDATE_RESOURCE_BY_REGION,
  CURRENT_FINDING,
} from '../../actions/types';

const INITIAL_STATE = {
  findingId: null,
  result: [],
  edges: { pending: 0, resolved: 0, archived: 0 },
};

export default function resourcesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FINDING_RESOURCE: {
      const {
        edges,
        edgesArchived,
        resources,
        finding,
      } = action.payload.data;
      return {
        ...state,
        result: resources,
        regions: finding?.regions,
        edges: {
          ...edges,
          archived: edgesArchived?.archived,
        },
        finding: {
          integration: finding?.integration,
          keyIdentifier: finding?.keyIdentifier ?? 'id',
          title: finding?.title,
          type: finding?.type,
        },
      };
    }
    case UPDATE_FINDING_RESOURCES: {
      let { currentTab } = action;
      const { payload } = action;
      let edgesUpdated = {};

      if (currentTab === 'All') {
        edgesUpdated = { total: payload?.edges?.total };
      }
      if (currentTab === 'Archived') {
        edgesUpdated = { archived: payload?.edgesArchived?.archived };
      } else {
        currentTab = currentTab.toLowerCase();
        edgesUpdated[currentTab] = payload?.edges[currentTab];
      }

      return {
        ...state,
        edges: {
          ...state?.edges,
          ...edgesUpdated,
        },
        result: payload?.resources,
      };
    }
    case UPDATE_RESOURCE_BY_REGION: {
      const { edges, edgesArchived } = action.payload;
      return {
        ...state,
        edges: {
          ...edges,
          archived: edgesArchived?.archived,
        },
      };
    }
    case CURRENT_FINDING:
      return {
        ...state,
        findingId: action.payload,
      };
    default:
      return state;
  }
}
