// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { JSONTree } from 'react-json-tree';

// Components, assets, actions, etc..
import './modal.css';
import CloseModalButton from './CloseModalButton';

function ResourcesModal(props) {
  const { t } = useTranslation();
  const { content, closeModal } = props;

  const theme = {
    scheme: 'Hypercube by Obok',
    author: 'fredyosorio (http://github.com/fredyosorio/)',
    base00: '#f9fbfd',
    base01: '#7c69ef',
    base02: '#7c69ef',
    base03: '#7c69ef',
    base04: '#7c69ef',
    base05: '#7c69ef',
    base06: '#7c69ef',
    base07: '#7c69ef',
    base08: '#7c69ef',
    base09: '#7c69ef',
    base0A: '#7c69ef',
    base0B: '#7c69ef',
    base0C: '#7c69ef',
    base0D: '#14192d',
    base0E: '#7c69ef',
    base0F: '#7c69ef',
  };

  const useRenderModalContent = () => {
    if (content?.content) {
      return (
        <JSONTree data={content.content} theme={theme} />
      );
    }
    return <JSONTree data={[]} theme={theme} />;
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src="https://cdn.gethypercube.com/logo512.png" alt="Logo" height="50" width="50" />
          <h6 className="header-pretitle mb-1">{t('components.modals.resourcesModal.resources')}</h6>
          <h1 className="header-title my-3">{content.title}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 mb-4">
        <div className="ml-5 resource-content">
          {useRenderModalContent()}
        </div>
      </div>
    </>
  );
}

ResourcesModal.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.shape(),
    type: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ResourcesModal;
