// Components, actions, assets etc..
import hypercube from '../../api/hypercube';
import { TOGGLE_MODAL } from '../types';

const billingRedirect = (properties) => async (dispatch) => {
  try {
    const { orgId } = properties;
    // send the default org Id
    const res = await hypercube.post(`/users/billing?orgId=${orgId}`, {});
    window.open(`${res.data.url}`, '_blank');
  } catch (err) {
    dispatch({ type: TOGGLE_MODAL, payload: { type: 'fail', from: 'billing redirect', err } });
  }
};

export default billingRedirect;
