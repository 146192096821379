// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import CloseModalButton from './CloseModalButton';

// Components, assets, actions etc..

function FailureModal(props) {
  const { content, closeModal } = props;
  const { message, response } = content.err;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center">Hypercube</h6>
            <h1 className="header-title text-center">Error</h1>
            <h1 className="header-title text-center">
              <strong>{response?.status || null}</strong>
            </h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column">
        <div className="text-center pb-5">
          <i className="display-1 text-danger fas fa-times" />
        </div>
        <div className="col">
          <h3 className="text-center">
            <strong>What happened?</strong>
          </h3>
          <h4 className="text-center">
            {`Your ${content.from} ${!!content.vendor && `for ${content.vendor}`
            } could not be completed due to the following error:`}
          </h4>
          <h4 className="text-center text-danger">
            {response?.data?.error || response?.data?._message || response?.data?.errors[0].msg || message || 'Internal Error' }
          </h4>
          <h4 className="text-center">
            A support ticket has been
            <strong> automatically generated </strong>
            to further investigate the issue.
          </h4>
        </div>
      </div>
    </>
  );
}

export default FailureModal;

FailureModal.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    from: PropTypes.string,
    vendor: PropTypes.string,
    err: PropTypes.shape({
      message: PropTypes.string,
      response: PropTypes.shape({
        status: PropTypes.number,
        data: PropTypes.shape({
          _message: PropTypes.string,
          error: PropTypes.string,
          errors: PropTypes.shape([{}]),
        }),
      }),
    }),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
