// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, assets, actions etc..
import IntegrationModal from './IntegrationModal';
import AzureIntegrationModal from './AzureIntegrationModal';

function IntegrationModalWrapper(props) {
  const { content, closeModal } = props;

  const renderIntegrationModal = () => {
    switch (content.vendor) {
      case 'Microsoft Azure':
        return <AzureIntegrationModal content={content} closeModal={closeModal} />;
      default:
        return <IntegrationModal content={content} closeModal={closeModal} />;
    }
  };

  return (
    <>
      {renderIntegrationModal()}
    </>
  );
}

IntegrationModalWrapper.propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default IntegrationModalWrapper;
