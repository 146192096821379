import React from 'react';
import MainLogo from '../../images/logos/obok-logo.svg';

function LoadingFallback() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="loading-appear">
        <svg width="200px" height="65px">
          <image
            href={MainLogo}
            className="hc-navbar-logo p-2"
            alt="Hypercube logo"
            width="100%"
          />
        </svg>
      </div>
    </div>
  );
}

export default LoadingFallback;
