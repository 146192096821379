// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components, actions, assets..
import CloseModalButton from './CloseModalButton';
import { deleteSaving, fetchSavings } from '../../actions/dashboard/savingsActions';
import { fetchSecurities } from '../../actions/dashboard/securityActions';

function DeleteFindingModal(props) {
  const dispatch = useDispatch();

  const { content, closeModal } = props;
  const { savingType, dataFilter, icon } = content;
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const deleteHandler = async () => {
    const isDeleted = await dispatch(deleteSaving(content.id));

    if (isDeleted) {
      if (savingType === 'Saving') dispatch(fetchSavings({ dataFilter, organizationId: currentOrganization._id }));
      else dispatch(fetchSecurities({ dataFilter, organizationId: currentOrganization._id }));
      closeModal();
    }
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={icon} alt="Logo" height="50" width="50" />
          <h6 className="header-pretitle text-center">
            Findings
          </h6>
          <h1 className="header-title text-center">
            Delete your finding
          </h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column align-items-center p-3">
        <div className="col pt-3">
          <h4 className="text-center">
            Are you sure you want to delete your finding?
          </h4>
          <h4 className="text-center">
            By deleting your finding, you will lose all related information.
          </h4>
          <h4 className="text-center">
            This operation
            <strong> can not be undone,</strong>
          </h4>
        </div>
        <div className="row pt-4">
          <button type="submit" className="btn btn-danger" onClick={deleteHandler}>
            Delete your finding
          </button>
        </div>
      </div>
    </>
  );
}

DeleteFindingModal.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    savingType: PropTypes.string,
    icon: PropTypes.string,
    dataFilter: PropTypes.shape({}),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteFindingModal;
