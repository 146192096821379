// Dependencies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components, actions, assets, etc..
import './table.css';

function GlobalFilter({
  updateDataHandler,
  searchInstructions,
  titleFilter = '',
  setTitleFilter = () => {},
}) {
  useEffect(() => {
    const timeoutId = setTimeout(() => updateDataHandler(titleFilter), 300);
    return () => clearTimeout(timeoutId);
  }, [titleFilter]);

  return (
    <div className="text-center filter-container">
      <input
        type="text"
        className="form-control"
        placeholder={searchInstructions}
        value={titleFilter || ''}
        onChange={(e) => setTitleFilter(e.target.value)}
      />
    </div>
  );
}

GlobalFilter.propTypes = {
  updateDataHandler: PropTypes.func.isRequired,
  searchInstructions: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  titleFilter: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  setTitleFilter: PropTypes.func,
};

export default GlobalFilter;
