// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
// Components, assets, actions etc..
import CloseModalButton from './CloseModalButton';

function ApplyModal(props) {
  const { content, closeModal } = props;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <img src={content.icon} alt="Logo" height="50" width="50" />
            <h6 className="header-pretitle mb-1">Apply</h6>
            <h1 className="header-title mb-4">{content.vendor}</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3">
        <div className="d-flex flex-column align-items-center">
          <h2 className="header-title mb-4">
            {content.title}
          </h2>
          <ol className="pl-0">
            <li>
              This
              {' '}
              {content.savingType}
              {' '}
              isn&apos;t enabled for your account.
              Do you want to enable it? Request access by clicking
              <a href="https://support.gethypercube.com/"> here</a>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default ApplyModal;

ApplyModal.propTypes = {
  content: PropTypes.shape({
    savingId: PropTypes.string,
    savingType: PropTypes.string,
    title: PropTypes.string,
    instructions: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
