// Dependencies
import {
  Form, Formik, Field,
} from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, assets, actions, etc..
import useForm from '../../../hooks/useForm';
import { checkEmail } from '../../../actions/userActions';

function CreateMembersInvitationsForm(props) {
  const { t } = useTranslation();
  const {
    onSubmitActions, organizationId,
  } = props;

  const { inviteAction, addMemberAction } = onSubmitActions;
  let selectedAction;
  const dispatch = useDispatch();
  const email = '';
  const role = 'guest';

  const validateEmail = async (value) => {
    let error;

    if (!value) {
      error = t('components.forms.organizations.createMembersInvitationsForm.isRequired');
      return error;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValid = emailRegex.test(value);

    if (!emailValid) {
      error = t('components.forms.organizations.createMembersInvitationsForm.invalidEmail');
      return error;
    }

    const existOnDb = await checkEmail(value);
    if (existOnDb) {
      selectedAction = addMemberAction;
    } else {
      selectedAction = inviteAction;
    }
    return error;
  };

  const { onSubmit } = useForm(async (body) => {
    const properties = {
      ...body,
      organizationId,
    };
    dispatch(selectedAction(properties));
  });

  return (
    <div className="d-flex justify-content-center mt-1 pr-5 pl-5 pt-3 pb-3">
      <Formik
        initialValues={{
          email,
          role,
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, isSubmitting }) => (
          <Form className="p-0 w-100">
            <div className="row">
              <div className="col-12 mb-3">
                <Field
                  className="form-control"
                  label="Email"
                  name="email"
                  placeholder="example@email.com"
                  validate={validateEmail}
                />
                {errors.email && <div className="mt-3 text-danger">{errors.email}</div>}
              </div>
              <div className="col-12 mb-3">
                <Field className="custom-select" name="role" as="select">
                  <option value="guest">Guest</option>
                  <option value="admin">Admin</option>
                </Field>
              </div>
            </div>
            <div className="card-footer d-flex w-100 pb-2">
              <button
                type="submit"
                className="btn btn-primary ml-auto"
              >
                {t('components.forms.organizations.createMembersInvitationsForm.submit')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

CreateMembersInvitationsForm.propTypes = {
  onSubmitActions: PropTypes.shape({
    inviteAction: PropTypes.func.isRequired,
    addMemberAction: PropTypes.func.isRequired,
  }).isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default CreateMembersInvitationsForm;
