// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components, actions, assets..
import CloseModalButton from './CloseModalButton';
import { fetchLastResources, archivedResource } from '../../actions/dashboard/resourceActions';

function ArchiveResourceModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { content, closeModal } = props;
  const { dataFilter, id, archived } = content;

  const findingId = useSelector((state) => state?.resource?.findingId);

  const archiveHandler = async () => {
    const isArchived = await dispatch(archivedResource({ id, archived, findingId }));

    if (isArchived) {
      await dispatch(fetchLastResources({ dataFilter, findingId }));
      closeModal();
    }
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src="https://cdn.gethypercube.com/logo512.png" alt="Logo" height="50" width="50" />
          <h6 className="header-pretitle text-center">
            {t('components.modals.archiveResourceModal.pretitle')}
          </h6>
          <h1 className="header-title text-center">
            {(archived) ? t('components.modals.archiveResourceModal.unarchiveTitle') : t('components.modals.archiveResourceModal.archiveTitle') }
          </h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column align-items-center p-3">
        <div className="col pt-3">
          <h4 className="text-center">
            {(archived) ? t('components.modals.archiveResourceModal.unarchiveMessage') : t('components.modals.archiveResourceModal.archiveMessage') }
          </h4>
        </div>
        <div className="row pt-4">
          <button type="submit" className={(archived) ? 'btn btn-success' : 'btn btn-danger'} onClick={archiveHandler}>
            {(archived) ? t('components.modals.archiveResourceModal.unarchive') : t('components.modals.archiveResourceModal.archive') }
          </button>
        </div>
      </div>
    </>
  );
}

ArchiveResourceModal.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    archived: PropTypes.bool,
    dataFilter: PropTypes.shape({}),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ArchiveResourceModal;
