import { SET_INTRO_INITIAL_STATE } from '../actions/types';

const INITIAL_STATE = {
  active: true,
  steps: [],
  tutorial: '',
};

export default function introReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_INTRO_INITIAL_STATE:
      return action.payload;
    //   return { ...state, introState: INITIAL_STATE };
    default:
      return state;
  }
}
