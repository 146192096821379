import hypercube from '../../api/hypercube';
import { lastMonth } from '../../helpers/date';
import {
  FEED_MODAL,
  FETCH_EXPENSES_BY_CATEGORY,
  FETCH_EXPENSES_BY_VENDOR,
} from '../types';

export const fetchExpensesByVendor = (properties) => async (dispatch) => {
  try {
    const { start, end } = lastMonth();
    const { data } = await hypercube
      .get(`/transactions/vendors/totalSpending?start=${start}&end=${end}&currency=USD&organizationId=${properties.organizationId}`);

    dispatch({
      type: FETCH_EXPENSES_BY_VENDOR,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FEED_MODAL, payload: { type: 'fail', from: 'charts', error } });
  }
};

export const fetchExpensesByCategory = (properties) => async (dispatch) => {
  try {
    const { start, end } = lastMonth();
    const { data } = await hypercube
      .get(`/transactions/categories/totalSpending?start=${start}&end=${end}&currency=USD&organizationId=${properties.organizationId}`);

    dispatch({
      type: FETCH_EXPENSES_BY_CATEGORY,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FEED_MODAL, payload: { type: 'fail', from: 'charts', error } });
  }
};
