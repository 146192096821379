// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';

function CurrentOrganizationModal(props) {
  const { t } = useTranslation();
  const { content, closeModal } = props;
  const { orgName } = content;

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="col">
            <h6 className="header-pretitle text-center mb-1">{t('components.modals.organizations.currentOrganizationModal.notice')}</h6>
            <h1 className="header-title text-center">{t('components.modals.organizations.currentOrganizationModal.changedOrganization')}</h1>
          </div>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column">
        <div className="text-center pb-5">
          <i className="display-1 text-success fa fa-exchange" />
        </div>
        <div className="col">
          <h3 className="text-center">
            <strong>{t('components.modals.organizations.currentOrganizationModal.operationAlert')}</strong>
          </h3>
          <h4 className="text-center">
            {`${t('components.modals.organizations.currentOrganizationModal.changedYourCurrent')} ${orgName}`}
          </h4>
        </div>
      </div>
    </>
  );
}

CurrentOrganizationModal.propTypes = {
  content: PropTypes.shape({
    orgName: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CurrentOrganizationModal;
