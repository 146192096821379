const TimeAgo = (dbDate) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const date = new Date(dbDate).getTime();
  const elapsed = Date.now() - date;

  if (elapsed < msPerMinute) {
    const time = Math.round(elapsed / 1000);
    return `about ${time} ${time > 1 ? 'seconds' : 'second'} ago`;
  } if (elapsed < msPerHour) {
    const time = Math.round(elapsed / msPerMinute);
    return `about ${time} ${time > 1 ? 'minutes' : 'minute'} ago`;
  } if (elapsed < msPerDay) {
    const time = Math.round(elapsed / msPerHour);
    return `about ${time} ${time > 1 ? 'hours' : 'hour'} ago`;
  } if (elapsed < msPerMonth) {
    const time = Math.round(elapsed / msPerDay);
    return `about ${time} ${time > 1 ? 'days' : 'day'} ago`;
  } if (elapsed < msPerYear) {
    const time = Math.round(elapsed / msPerMonth);
    return `about ${time} ${time > 1 ? 'months' : 'month'} ago`;
  }
  const time = Math.round(elapsed / msPerYear);
  return `about ${time} ${time > 1 ? 'years' : 'year'} ago`;
};

export default TimeAgo;
