// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components, actions, assets, etc..
import { Link } from 'react-router-dom';
import TimeAgo from '../widgets/TimeAgo';

function SavingActivityItem(props) {
  const { title, icon, createdAt } = props;

  return (
    <Link to="/dashboard/savings" className="text-dark">
      <div className="list-group-item cursor-pointer">
        <div className="row">
          <div className="col-auto">
            <div className="avatar avatar-sm avatar-online">
              <img className="avatar-img rounded-circle" src={icon} alt="Vendor" />
            </div>
          </div>
          <div className="col ml-n2">
            <h5 className="mb-1 black">{title}</h5>
            <p className="text-muted text-first-letter-capitalize">
              <small>{TimeAgo(createdAt)}</small>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SavingActivityItem;

SavingActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};
