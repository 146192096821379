// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components, assets, actions, etc..
function TableResultFiller() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid py-5 my-3 px-0 mx-0 row rounded">
      <div className="col-md-12 text-center text-md-left" data-aos="fade-up">
        <p className="font-size-lg text-center text-muted">
          {t('components.fillers.tableResultFiller.noResults')}
        </p>
      </div>
    </div>
  );
}

export default TableResultFiller;
