// Dependencies
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

// Components, assets, actions etc..
import Tooltip from '../ui/Tooltip';

function CopyHandler(props) {
  const { toCopy } = props;
  const [isCopied, setCopied] = useState(false);

  const copyHandler = async () => {
    await navigator.clipboard.writeText(toCopy);
    setCopied(true);
  };

  return (
    <>
      <div
        className="text-nowrap bg-light p-1 "
        style={{ width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}
      >
        {toCopy}
      </div>
      <Tooltip text="Copy">
        <Button
          className="btn btn-sm"
          variant={isCopied ? 'success' : 'primary'}
          onClick={copyHandler}
        >
          <i className="far fa-copy" />
        </Button>
      </Tooltip>
    </>
  );
}

CopyHandler.propTypes = {
  toCopy: PropTypes.string.isRequired,
};

export default CopyHandler;
