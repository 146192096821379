/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {
  Button, Col, Dropdown, Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpendingTotal, fetchTransactions } from '../../actions/spending/transactionsActions';
import { fetchThreatsActive, fetchThreatsIdentified } from '../../actions/dashboard/widgetsActions';

import 'react-datepicker/dist/react-datepicker.css';
import { fetchSavings } from '../../actions/dashboard/savingsActions';
import { fetchSecurities } from '../../actions/dashboard/securityActions';
import Switch from '../common/inputs/baseSwitch';
import './styles.css';
import { RESET_FILTERS, SET_FILTERS } from '../../actions/types';
import { vendors } from '../../constants/clouds';
import BaseMultipleOtionsDropdown from '../common/inputs/baseDropdown';
import SearchableDropdown from '../common/inputs/searchableDropdown';
import { fetchIntegrations } from '../../actions/dashboard/integrationsActions';
import BaseButton from '../common/inputs/baseButton';

const vendorsOptions = [vendors.AWS, vendors.Azure, vendors.GCP, vendors.OCI];

const smallBtnStyles = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: '#9a90ee',
};

const filterItemHeader = ({ title }) => (
  <div className="d-flex justify-content-between align-items-center mb-3">
    <h3 className="card-header-title mr-2">{title}</h3>
  </div>
);

function DashboardFilters({ t, organizationId }) {
  const dispatch = useDispatch();
  const { filters: filtersState, vendors: vendorsState, integrations } = useSelector((state) => state);

  const integrationsList = integrations?.all?.result.map((integration) => integration.name) || [];
  const selectedTransaction = filtersState.integration;

  const onFiltersSubmit = async () => {
    const {
      inUse, filterSwitch, integration, ...filters
    } = filtersState;

    const integrationId = integrations?.all?.result.find((integrationItem) => integrationItem.name === integration)?._id;

    const endVendors = filtersState.vendors.map((vendor) => vendor._id);

    const threatFiltersFormat = {
      filters: {
        start_date: filtersState.start,
        end_date: filtersState.end,
        type: filtersState.type,
        vendors: endVendors,
        integrationId,
      },
    };
    await dispatch(fetchTransactions({
      organizationId,
      limit: 'all',
      dataFilter: {
        ...filters,
        ...(filters.vendors && { vendors: endVendors }),
        integrationId,
      },
    }));
    await dispatch(fetchThreatsIdentified({
      organizationId,
      ...threatFiltersFormat,
    }));
    await dispatch(fetchThreatsActive({
      organizationId,
      ...threatFiltersFormat,
    }));

    await dispatch(fetchSavings({
      organizationId,
      dataFilter: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
        ...(filters.vendors && { vendors: endVendors }),
      },
    }));
    await dispatch(fetchSecurities({
      organizationId,
      dataFilter: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
        ...(filters.vendors && { vendors: endVendors }),
      },
    }));
    await dispatch(fetchSpendingTotal({
      organizationId,
      filters: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
        ...(filters.vendors && { vendors: endVendors }),
      },
    }));
  };

  // const filterReset = (keyName) => {
  //   dispatch({ type: SET_FILTERS, payload: { [keyName]: null } });
  // };

  const onVendorSelection = (option) => {
    const selectedVendor = vendorsState.find((vendor) => vendor.name === option);

    const activeFilters = filtersState?.vendors || [];
    const restoredVendors = activeFilters.find((filter) => filter.name === option)
      ? activeFilters?.filter((item) => item.name !== option)
      : [...activeFilters, selectedVendor];

    dispatch({ type: SET_FILTERS, payload: { vendors: restoredVendors } });
  };

  const resetAllFilters = () => {
    dispatch({ type: RESET_FILTERS });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title my-2">{t('views.dashboard.filters.title')}</h4>
            <Switch
              isOn={filtersState.filterSwitch}
              handleToggle={() => dispatch({ type: SET_FILTERS, payload: { filterSwitch: !filtersState.filterSwitch } })}
            />
          </div>
          {
            filtersState.filterSwitch ? (
              <div className="card-body animated-fade">
                <Col className="ml-3" xs={12} md={6} lg={6}>
                  { filterItemHeader({ title: 'Date Range' }) }
                </Col>
                <Col className="row ml-3" xs={12} md={6}>
                  <Col className="filter-col-style my-3">
                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.startDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control flex-grow-1"
                      selected={filtersState.start}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { start: date } })}
                    />
                  </Col>
                  <Col className="filter-col-style my-3">
                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.endDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control"
                      selected={filtersState.end}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { end: date } })}
                    />
                  </Col>
                </Col>
                <Dropdown.Divider />

                <Col className="row ml-0" lg={12}>
                  <Col>
                    { filterItemHeader({ title: 'Finding' }) }
                    <Form.Select
                      className="form-control choose-finding-type"
                      onChange={(evt) => dispatch({ type: SET_FILTERS, payload: { type: evt.target.value } })}
                    >
                      <option>{t('views.dashboard.filters.findingTypeLabel')}</option>
                      <option value="Security">{t('views.dashboard.security.title')}</option>
                      <option value="Saving">{t('components.organizations.organizationCard.savings')}</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    { filterItemHeader({ title: 'Vendor' }) }
                    <BaseMultipleOtionsDropdown
                      entity="vendors"
                      optionsList={vendorsOptions}
                      selectedList={filtersState?.vendors?.map((vendor) => vendor.name)}
                      onToggleSelection={(items) => onVendorSelection(items)}
                    />
                  </Col>
                  <Col>
                    { filterItemHeader({ title: 'Integration' }) }
                    <SearchableDropdown
                      title={t('views.dashboard.filters.integrationLabel')}
                      onSearch={(keyword) => keyword.length > 0 && dispatch(fetchIntegrations({ organizationId, dataFilter: { filter: keyword } }))}
                      selection={selectedTransaction}
                      optionsList={integrationsList}
                      onSelectionChange={(e) => dispatch({ type: SET_FILTERS, payload: { integration: e, integrationId: integrations?.all?.result.find((integration) => integration.name === e)?._id } })}
                      isLoading={integrations.isLoading}
                    />

                  </Col>
                </Col>
                <br />
                <Dropdown.Divider />

                <Col
                  xs={12}
                  md={7}
                  className="d-flex justify-content-between align-items-center mt-3"
                >
                  <BaseButton
                    variant="secondary"
                    styles={smallBtnStyles}
                    label="Filters Reset"
                    handleClick={() => resetAllFilters()}
                  />

                  <Button
                    variant="primary"
                    onClick={() => onFiltersSubmit()}
                  >
                    {t('views.dashboard.filters.submitFilters')}
                  </Button>
                </Col>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

DashboardFilters.propTypes = {
  t: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default DashboardFilters;
