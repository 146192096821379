import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (!BACKEND_URL) throw new Error('REACT_APP_BACKEND_URL is not defined.');

export default axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});
