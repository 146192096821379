// Dependencies
import React from 'react';
import currencySymbols from '../../constants/currencySymbols';
import getCurrencyAmount from '../../helpers/getCurrencyAmount';

export default (headerTranslations) => (
  [
    {
      Header: headerTranslations.integration,
      Cell: (row) => (
        <>
          <div className="avatar avatar-sm mx-auto">
            <img src={row.value.icon} alt={row.value.icon} className="avatar-img rounded" />
          </div>
          <p className="mb-0 text-secondary">{row.value.integration}</p>
        </>
      ),
      accessor: 'vendor',
    },
    {
      Header: headerTranslations.category,
      accessor: 'category',
    },
    {
      Header: headerTranslations.spending,
      Cell: (row) => (
        <span>
          {`${getCurrencyAmount(row.value.spending, row.value.currency)}`}
        </span>
      ),
      accessor: 'spending',
    },
    {
      Header: headerTranslations.currency,
      Cell: (row) => (
        <>
          <i className={`${currencySymbols[row.value].flag_icon} mr-1`} />
          <p className="m-0 mr-1">{row.value}</p>
        </>
      ),
      accessor: 'currency',
    },
    {
      Header: headerTranslations.purchasedAt,
      accessor: 'purchased',
    },
  ]
);
