import { FETCH_NOTIFICATIONS, FETCH_NOTIFICATIONS_EDGES, FETCH_LATEST_NOTIFICATIONS } from '../../actions/types';

const INITIAL_STATE = null;

export default function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_NOTIFICATIONS_EDGES:
      return {
        ...state,
        edges: action.payload,
      };
    case FETCH_LATEST_NOTIFICATIONS:
      return {
        ...state,
        latest: action.payload,
      };
    default:
      return state;
  }
}
