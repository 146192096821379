// Dependencies
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import parse from 'html-react-parser';

// Components, assets, actions etc..
import CloseModalButton from '../CloseModalButton';
import CopyHandler from '../../common/CopyHandler';
import { connectIntegration } from '../../../actions/dashboard/integrationsActions';
import useForm from '../../../hooks/useForm';
import FormInput from '../../forms/FormInput';

function AzureIntegrationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { content, closeModal } = props;

  const [subscriptionId, setSubscriptionId] = useState('');
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);

  const command = `az ad sp create-for-rbac --name Hypercube --role Reader --scopes /subscriptions/${subscriptionId}`;
  const { instructions } = content;

  const { onSubmit } = useForm((body) => {
    dispatch(connectIntegration({ ...body, organizationId: currentOrganization._id }, 'azure'));
  });
  const validateFields = (values) => {
    const errors = {};

    const blankSpaceError = t('components.modals.integrations.azureIntegrationModal.blankSpaces');
    if (!values.subscriptionId) {
      errors.subscriptionId = t('components.modals.integrations.azureIntegrationModal.required');
    } else if (/\s/.test(values.subscriptionId)) {
      errors.subscriptionId = blankSpaceError;
    }

    if (!values.tenantId) {
      errors.tenantId = t('components.modals.integrations.azureIntegrationModal.required');
    } else if (/\s/.test(values.tenantId)) {
      errors.tenantId = blankSpaceError;
    }

    if (!values.clientId) {
      errors.clientId = t('components.modals.integrations.azureIntegrationModal.required');
    } else if (/\s/.test(values.clientId)) {
      errors.clientId = blankSpaceError;
    }

    if (!values.secret) {
      errors.secret = t('components.modals.integrations.azureIntegrationModal.required');
    } else if (/\s/.test(values.secret)) {
      errors.secret = blankSpaceError;
    }

    return errors;
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <img src={content.icon} alt="Logo" height="70" width="70" />
          <h6 className="header-pretitle mb-1">{t('components.modals.integrations.azureIntegrationModal.instructions')}</h6>
          <h1 className="header-title">{content.vendor}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3">
        <div className="d-flex justify-content-center mt-1 pr-5 pl-5 pt-3 pb-3">
          <Formik
            initialValues={{
              subscriptionId: '',
              tenantId: '',
              clientId: '',
              secret: '',
              name: '',
              description: '',
              type: 'azure',
            }}
            validate={validateFields}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, errors }) => (
              <Form className="p-0 w-100">
                <ol className="m-0 p-0">
                  <div className="form-group mb-3">
                    <li className="m-0 py-1 px-1 mb-3">
                      {parse(instructions[0])}
                    </li>
                    <div className="col-sm-12">
                      <FormInput
                        errors={errors}
                        name="subscriptionId"
                        placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                        onKeyUp={(e) => setSubscriptionId(e.target.value)}
                        inline
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <li
                      className="m-0 py-1 px-1 mb-3"
                      key={instructions[1]}
                    >
                      {parse(instructions[1])}
                    </li>
                    <div className="col-sm-12 d-flex">
                      <CopyHandler toCopy={command} />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <li
                      className="m-0 py-1 px-1"
                      key={instructions[2]}
                    >
                      {parse(instructions[2])}
                    </li>
                  </div>
                </ol>
                <div className="row">
                  <div className="col-6">
                    <FormInput
                      errors={errors}
                      label={t('components.modals.integrations.azureIntegrationModal.name')}
                      name="name"
                      placeholder={t('components.modals.integrations.azureIntegrationModal.exName')}
                      inline={false}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      errors={errors}
                      label={t('components.modals.integrations.azureIntegrationModal.description')}
                      name="description"
                      placeholder={t('components.modals.integrations.azureIntegrationModal.exDescription')}
                      inline={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <FormInput
                      errors={errors}
                      label={t('components.modals.integrations.azureIntegrationModal.appId')}
                      name="clientId"
                      placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                      inline={false}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      errors={errors}
                      label={t('components.modals.integrations.azureIntegrationModal.tenantId')}
                      name="tenantId"
                      placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                      inline={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <FormInput
                      errors={errors}
                      label={t('components.modals.integrations.azureIntegrationModal.password')}
                      name="secret"
                      placeholder="XXXXXXXX-XXXX-XXXXXXXXXXXX"
                      inline={false}
                    />
                  </div>
                </div>
                <div className="card-footer d-flex w-100 pb-2">
                  <button
                    type="submit"
                    className="btn btn-primary col-md-2 ml-auto"
                    disabled={isSubmitting}
                  >
                    {t('components.modals.integrations.azureIntegrationModal.connect')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

AzureIntegrationModal.propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AzureIntegrationModal;
