// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';

function LeaveOrganizationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { content, closeModal } = props;
  const {
    modalAction, userId, organizationId, email,
  } = content;

  const leaveOrganization = async () => {
    const properties = {
      organizationId,
      id: userId,
      email,
      owner: true,
    };
    await dispatch(modalAction(properties));
    closeModal();
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={content.icon} className="img-fluid mb-2" alt="Logo" height="50" width="50" />
          <h1 className="header-title text-center">
            {t('components.modals.organizations.leaveOrganizationModal.title')}
          </h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-3">
          <h4 className="text-center">{t('components.modals.organizations.leaveOrganizationModal.areYouSure')}</h4>
          <h4 className="text-center">
            {t('components.modals.organizations.leaveOrganizationModal.thisOperation')}
            <strong>{t('components.modals.organizations.leaveOrganizationModal.cannotBe')}</strong>
          </h4>
        </div>
        <div className="row justify-content-center pt-4">
          <button type="submit" className="btn btn-danger" onClick={leaveOrganization}>
            {t('components.modals.organizations.leaveOrganizationModal.leave')}
          </button>
        </div>
      </div>
    </>
  );
}

LeaveOrganizationModal.propTypes = {
  content: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    modalAction: PropTypes.func.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LeaveOrganizationModal;
