import { FETCH_VENDORS } from '../../actions/types';

const INITIAL_STATE = null;

export default function vendorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VENDORS:
      return action.payload;
    default:
      return state;
  }
}
