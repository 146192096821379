// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components, actions, assets..
import CloseModalButton from '../CloseModalButton';

function DeleteMemberInvitationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { content, closeModal } = props;
  const {
    modalAction, name, id, organizationId,
  } = content;

  const deleteHandler = async () => {
    const properties = {
      organizationId,
      id,
      email: name,
    };
    await dispatch(modalAction(properties));
    closeModal();
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 h-50 d-flex flex-column align-items-center">
          <img src={content.icon} className="img-fluid mb-2" alt="Logo" height="50" width="50" />
          <h1 className="header-title text-center">
            {(content.isMember) ? t('components.modals.organizations.deleteMemberInvitationModal.titleMember') : t('components.modals.organizations.deleteMemberInvitationModal.titleInvitation')}
          </h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3 container-fluid">
        <div className="col pt-3">
          <h4 className="text-center">
            {(content.isMember)
              ? t('components.modals.organizations.deleteMemberInvitationModal.areYouSureMember', { content })
              : t('components.modals.organizations.deleteMemberInvitationModal.areYouSureInvitation', { content })}
          </h4>
          <h4 className="text-center">
            {t('components.modals.organizations.deleteMemberInvitationModal.thisOperation')}
            <strong>{t('components.modals.organizations.deleteMemberInvitationModal.cannotBe')}</strong>
          </h4>
        </div>
        <div className="row justify-content-center pt-4">
          <button type="submit" className="btn btn-danger" onClick={deleteHandler}>
            {(content.isMember) ? t('components.modals.organizations.deleteMemberInvitationModal.remove') : t('components.modals.organizations.deleteMemberInvitationModal.revoke')}
          </button>
        </div>
      </div>
    </>
  );
}

DeleteMemberInvitationModal.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isMember: PropTypes.bool.isRequired,
    modalAction: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteMemberInvitationModal;
