// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..
import PropTypes from 'prop-types';
import LineSpinner from '../spinners/LineSpinner';

function DataGraph(props) {
  const { t } = useTranslation();
  const {
    data, title, charts,
  } = props;

  const Component = charts[0];

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{`${title}`}</h4>
      </div>
      <div className="card-body d-flex align-items-center justify-content-center p-3">
        {data ? <Component data={data} /> : <LineSpinner name={t('components.charts.dataGraph.spinners')} />}
      </div>
    </div>
  );
}

DataGraph.propTypes = {
  title: PropTypes.string.isRequired,
  charts: PropTypes.any.isRequired,
};

DataGraph.defaultProps = {
};

export default DataGraph;
