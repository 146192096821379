import {
  FETCH_EXPENSES_BY_CATEGORY,
  FETCH_EXPENSES_BY_VENDOR,
} from '../../actions/types';

const INITIAL_STATE = {
  expensesMonth: 0,
  savingsMonth: 0,
  expensesByVendor: {},
  expensesByCategory: {},
};

export default function chartsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_EXPENSES_BY_VENDOR:
      return {
        ...state,
        expensesByVendor: action.payload,
      };
    case FETCH_EXPENSES_BY_CATEGORY:
      return {
        ...state,
        expensesByCategory: action.payload,
      };
    default:
      return state;
  }
}
