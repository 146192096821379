import { combineReducers } from 'redux';
import authReducer from './authReducer';
import introReducer from './introReducer';
import modalReducer from './modalReducer';
import navigationReducer from './navigationReducer';
import routerReducer from './routerReducer';
import apiKeysReducer from './dashboard/apiKeysReducer';
import insightsReducer from './dashboard/insightsReducer';
import integrationsReducer from './dashboard/integrationsReducer';
import notificationsReducer from './dashboard/notificationsReducer';
import resourceReducer from './dashboard/resourcesReducer';
import savingsReducer from './dashboard/savingsReducer';
import securityReducer from './dashboard/securityReducer';
import vendorReducer from './dashboard/vendorReducer';
import widgetReducer from './dashboard/widgetReducer';
import organizationsReducer from './organization/organizationReducer';
import chartsReducer from './spending/chartsReducer';
import transactionsReducer from './spending/transactionsReducer';
import filterReducer from './filtersReducer';
import onboardingReducer from './onboarding/onboardingReducer';

export default combineReducers({
  apiKeys: apiKeysReducer,
  auth: authReducer,
  charts: chartsReducer,
  insights: insightsReducer,
  integrations: integrationsReducer,
  intro: introReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  notifications: notificationsReducer,
  organizations: organizationsReducer,
  resource: resourceReducer,
  routes: routerReducer,
  savings: savingsReducer,
  security: securityReducer,
  transactions: transactionsReducer,
  vendors: vendorReducer,
  widgets: widgetReducer,
  filters: filterReducer,
  onboarding: onboardingReducer,
});
